import { dateTime } from '@/utils/convert';
import { EWB_TYPES } from '@/utils/constants';

export const entity = [
  {
    key: 'documentFlowId',
    label: 'УИД_ПЛ',
    headerProps: {
      width: '22%',
    },
  },
  {
    key: 'documentType',
    label: 'Тип титула',
    convert: ({ documentType }) =>
      EWB_TYPES.find(e => {
        return e.value === documentType;
      })?.name || '-',
  },
  {
    key: 'failed',
    label: 'Статус',
    headerProps: {
      width: '100px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
  },
  {
    key: 'inspectionId',
    label: 'ID осмотра',
  },
  {
    key: 'inspectionProcessedAt',
    label: 'Дата, время осмотра',
    headerProps: {
      width: '150px',
    },
    convert: ({ inspectionProcessedAt }) =>
      dateTime(inspectionProcessedAt) || '–',
  },

  {
    key: 'employeeFIO',
    label: 'ФИО/Организация',
  },
  {
    key: 'appName',
    label: 'Оператор ЭПЛ',
  },
  {
    key: 'formationAt',
    label: 'Дата, время формирования титула',
    convert: ({ formationAt }) =>
      dateTime(formationAt, { showSeconds: true }) || '–',
  },
  {
    key: 'shiftDateStart',
    label: 'Дата действия путевого листа',
    convert: ({ shiftDateStart, shiftDateEnd }) =>
      dateTime(shiftDateStart) + '-' + dateTime(shiftDateEnd) || '–',
  },
];

export const messages = {
  resourceName: 'титулов',
  searchFilterLabel: 'Поиск по осмотрам',
  searchFilterPlaceholder: '',
};

export const filters = {
  appAccountIds: { type: Array, default: [] },
  orgIds: {
    type: Array,
    default: null,
  },
  employeeIds: { type: Array, default: null },
  types: { type: Array, default: [] },
  inspectionId: { type: String, default: null },
  documentFlowId: { type: String, default: null },
  shiftDateFrom: { type: String, default: null },
  shiftDateTo: { type: String, default: null },
};
