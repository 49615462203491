<template>
  <div
    class="limits__container"
    :style="[!groupp && { width: '700px', margin: '0 auto' }]"
  >
    <Card title="Список лимитов">
      <template #action>
        <v-menu v-if="$can('LM_C') && canAddLimits" bottom left>
          <template #activator="{ on, attrs }">
            <ListActionBtn
              v-bind="attrs"
              label="Добавить"
              icon="mdi-plus-box-outline"
              v-on="on"
            />
          </template>
          <v-list>
            <v-list-item
              v-for="[key, value] in limitTypeNameList"
              :key="key"
              @click="openModalCreate(key)"
            >
              <v-list-item-title>{{ value }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <NoDataAlert
        v-if="
          groupp && activeGroupOrgLimits.length && !$wait('fetchListHandler')
        "
      >
        Невозможно создать лимит для группы организаций, в которых состоят
        организации с действующими лимитами.
        <br />
        Организации с лимитами, состоящие в группе:
        <LinkButtonList :list="orgsAsButton" class="my-2" />
      </NoDataAlert>

      <NoDataAlert
        v-if="
          !list.length &&
          !activeGroupOrgLimits.length &&
          !forbiddenCreateLimit &&
          !$wait('fetchListHandler')
        "
      >
        Для {{ groupp ? 'группы' : 'организации' }} нет созданных лимитов
      </NoDataAlert>

      <NoDataAlert v-if="forbiddenCreateLimit">
        Невозможно создать лимит для организации, которая состоит в группах
        организаций, у которых установлены лимиты.
        <br />
        Группы организаций с лимитами, в которых состоит текущая организация:
        <LinkButtonList :list="orgGroupsAsButton" class="my-2" />
      </NoDataAlert>

      <AsyncWrapper
        :class="{ 'limit-card__list': list.length }"
        :handler="fetchListHandler"
      >
        <div v-if="list.length">
          <LimitCard
            v-for="(el, index) in list"
            :key="el.id"
            :value="el"
            :groupp="groupp"
            :class="index !== list.length - 1 && 'mb-4'"
          />
        </div>
        <div v-else />
      </AsyncWrapper>
    </Card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import limitsApi from '@/api/services/limits';
import structApi from '@/api/services/structures';
import waitable from '@/utils/mixins/waitable';
import { LimitTypeName } from '@/api/services/limits.interfaces';

// components
import LimitCard from '@/components/limits/LimitCard';
import Card from '@/components/ui/Card';
import AsyncWrapper from '@/components/AsyncWrapper';
import LinkButtonList from '@/components/controls/buttons/LinkButtonList.vue';
import NoDataAlert from '@/components/ui/NoDataAlert';
import ListActionBtn from '@/components/controls/buttons/ListActionBtn';

export default {
  components: {
    LimitCard,
    AsyncWrapper,
    Card,
    LinkButtonList,
    NoDataAlert,
    ListActionBtn,
  },
  mixins: [waitable],
  props: {
    // NOTE: limits functionality may present in 2 modules - org and org_groups
    // 'p' at the end i borrowed from lisp ecosystem, which means 'predicate'.
    // For example zerop function determines whether passed value is zero or
    // not.  I chose that name cuz 'isGroup' kinda doesn't fit well into meaning
    // while 'groupp[redicate]' is more abstract
    groupp: Boolean, // explanation of naming here ↑
    orgOrGroupId: { type: Number, required: true },
    org: { type: Object, default: () => null },
  },

  data: () => ({
    groupLimitsPreviews: [],
    activeGroupOrgLimits: [],
  }),

  computed: {
    ...mapGetters('STRUCTURES/LIMITS', ['list', 'groupOrgLimitPreviews']),
    ...mapGetters('STRUCTURES/ORGANIZATION_GROUPS_ITEM', ['listItems']),

    isLoading() {
      return (
        this.$wait('fetchListHandler') || this.$wait('fetchGroupLimitsLoading')
      );
    },

    forbiddenCreateLimit() {
      return !this.groupp && this.groupLimitsPreviews.length;
    },
    limitTypeNameList() {
      return Object.entries(LimitTypeName);
    },

    orgGroupsAsButton() {
      if (this.groupp) return [];
      return this.groupLimitsPreviews.map(el => ({
        text: el.name || `#${el.id}`,
        action: this.$can('OG_RD'),
        to: {
          name: 'structures:organization_groups:item',
          params: { id: el.id },
        },
      }));
    },

    orgsAsButton() {
      if (!this.groupp) return [];

      // getting information about group orgs from 'listItems' org group store
      // for each limit in each group organization we need to form an object
      const limitInfoArr = [];
      this.groupOrgLimitPreviews.items.forEach(limit => {
        // do not allow for duplicates
        if (!limitInfoArr.some(el => el.id === limit.orgOrGroupId))
          if (limit.isEnabled) {
            const orgByLimit = this.listItems.find(
              org => org.id === limit.orgOrGroupId,
            );
            const displayLimitsOrg = orgByLimit || {
              id: limit.orgOrGroupId,
            };
            limitInfoArr.push(displayLimitsOrg);
          }
      });
      return limitInfoArr.map(el => ({
        text: el.name || `#${el.id}`,
        action: this.$can('ORG_RD'),
        to: {
          name: 'structures:organizations:item',
          params: { id: el.id },
        },
      }));
    },

    canAddLimits() {
      if (this.isLoading || this.forbiddenCreateLimit) return false;

      if (this.groupp) {
        if (this.activeGroupOrgLimits.length) return false;
      } else {
        if (!this.groupLimitsPreviews.length) return true;

        // if we r working with org and it is part of org groups with limits
        const groupsWithLimits = !!this.groupLimitsPreviews.length;
        if (groupsWithLimits && this.list.length > 0) return false;
      }

      return true;
    },
  },

  watch: {
    groupOrgLimitPreviews: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!val.items) return;
        this.activeGroupOrgLimits = val.items.filter(limit => limit.isEnabled);
      },
    },
  },

  created() {
    if (!this.groupp)
      this.$loading(this.fetchGroupLimits(), 'fetchGroupLimitsLoading');
  },

  methods: {
    ...mapActions('STRUCTURES/LIMITS', [
      'fetchList',
      'fetchReceiverPreviews',
      'fetchGroupOrgLimitPreviews',
    ]),

    // expects to get group previews, rather than array of ids
    async fetchGroupLimits() {
      // org object might not present (in case user followed link or smth)
      // HACK: since there is no 'groups' array in org previews request we
      // fetch whole org
      const org = this.org
        ? { ...this.org }
        : await structApi.organizationGet(this.orgOrGroupId);

      // store limits of each orggroup in this array and filter out limits
      // by date and only active
      const fetches = org.groups.map(async group => {
        let limits = await limitsApi.limitByOrgGroupGet(group.id);
        limits = limits.filter(limit => limit.isEnabled);
        limits.length && (group.limits = limits);
        return group;
      });
      const groupLimitsPreviews = await Promise.all(fetches);

      // leave only groups that have some limits not by date and only active
      this.groupLimitsPreviews = groupLimitsPreviews.filter(
        group => group.limits,
      );
    },

    fetchListHandler() {
      if (this.groupp) {
        this.$loading(
          this.fetchGroupOrgLimitPreviews({
            page: 1,
            limit: 1000,
            inGroupId: this.orgOrGroupId,
          }),
          'fetchListHandler',
        );
      }

      // Вне зависимости от того, можно ли добавлять лимиты,
      // загружаем список лимитов, тк могут быть неактивированные лимиты
      const result = this.fetchList({
        orgOrGroupId: this.orgOrGroupId,
        groupp: this.groupp,
      }).then(() => {
        if (this.$can('AC_PRW')) {
          this.fetchReceiverPreviews();
        }
      });

      this.$loading(result, 'fetchListHandler');

      return result;
    },

    openModalCreate(key) {
      this.$openModal('changeLimit', {
        type: key,
        orgOrGroupId: this.orgOrGroupId,
        groupp: this.groupp,
        onSubmit: () => this.init(),
      });
    },
  },
};
</script>
<style scoped lang="scss">
.limit-card__list:not(:first-child) {
  margin-top: 16px;
}
</style>
