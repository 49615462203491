import Vue from 'vue';
import { min } from 'lodash';
import { minLength, maxLength } from 'vuelidate/lib/validators';
import resourceListFactory from '../../utils/mixins/resourceListFactory';
import ListPageLayout from '@/components/layouts/ListPageLayout';
import EmbeddedListLayout from '@/components/layouts/EmbeddedListLayout';
import { VPagination, VBtn, VDataTable, VCol, VTextField } from 'vuetify/lib';
import Spinner from '@/components/Spinner.vue';
import IconActions from '@/components/crud/IconActions.vue';
import ExportButton from '@/components/crud/ExportButton.vue';
import ListActionBtn from '@/components/controls/buttons/ListActionBtn';
import { entityToHeaders } from '@/utils/entities';
import validationMixin from '@/utils/validation';
import { isXhrError, xhrErrorMessage } from '@/utils/helpers';

export default ({
  storeModule,
  messages = {},
  entity = [],
  filters = {},
  viewRoute = '/',
  embedded = false,
}) => {
  const headers = entityToHeaders(entity);
  const entityForImport = entity.filter(field => field.forImport);

  return Vue.extend({
    mixins: [
      resourceListFactory({
        storeModule,
        filters,
        resourceName: messages?.resourceName,
        options: { autoWatch: false },
      }),
      validationMixin,
    ],
    props: {
      searchFilter: { type: Boolean, default: false },
      availableActions: {
        type: Object,
        default: () => ({
          createAction: true,
          showAction: false,
          importAction: false,
          exportAction: false,
          goByIdAction: false,
        }),
      },
      exportLimit: { type: Number, default: 0 },
      // NOTE: meme crutch that allows us to know beforehand if
      // any of slot actions will be shown
      canShowScopedActions: { type: Boolean, default: true },
      /**
       * Настройки для импорта
       */
      importSettings: {
        type: Object,
        default: () => ({
          chooseOnlyCreate: true,
        }),
      },
    },
    data() {
      return {
        currentItemId: null,
        needApplyFilters: false,
      };
    },
    validations() {
      return {
        listSearch: {
          minSymbolsLength: minLength(2),
          maxSymbolsLength: maxLength(100),
        },
      };
    },
    methods: {
      // Renderers
      renderTitleActions() {
        let actions = [];

        if (this.availableActions.createAction)
          actions.push(
            <ListActionBtn
              label="Добавить"
              icon="mdi-plus-box-outline"
              onClick={this.$listeners['item:create']}
            />,
          );

        if (this.$scopedSlots['header.actions'])
          actions = [...actions, this.$scopedSlots['header.actions'](this)];

        if (this.availableActions.importAction)
          actions.push(
            <ListActionBtn
              label="Импортировать"
              icon="mdi-database-import-outline"
              onClick={this.openImportModal}
            />,
          );

        if (this.availableActions.exportAction) {
          const limitExceeded = this.listTotal > this.exportLimit;
          actions.push(
            <ExportButton
              limit={this.listIsLoading ? null : this.exportLimit}
              disabled={this.listIsLoading || limitExceeded}
              loading={this.listExportLoading}
              onClick={this.exportData}
            />,
          );
        }

        if (this.availableActions.goByIdAction)
          actions.push(
            <ListActionBtn
              label="Перейти по ID"
              icon="mdi-routes"
              onClick={this.openGoByIdModal}
            />,
          );

        return actions;
      },

      renderList() {
        const itemNamedSlots = Object.fromEntries(
          headers
            .filter(item => item.convert)
            .map(item => [
              'item.' + item.value,
              payload => item.convert(payload.item, this.$store.getters),
            ]),
        );
        const itemCustomSlots = Object.fromEntries(
          Object.keys(this.$scopedSlots)
            .filter(key => /^table\./.test(key))
            .map(key => [key.slice(6), this.$scopedSlots[key]]),
        );

        const listHasScopedActions =
          this.$scopedSlots['item.actions'] ||
          this.$scopedSlots['item.actions.prepend'] ||
          this.$scopedSlots['item.actions.append'];

        // show actions column flag - show only if at least 1 action anywhere
        const showActionsColumn =
          (listHasScopedActions && this.canShowScopedActions) ||
          this.availableActions.showAction;

        const top = this.renderTopSlot();

        return this.$createElement(
          VDataTable,
          {
            props: {
              loadingText: '',
              disableSort: true,
              headers: showActionsColumn
                ? headers
                : headers.filter(val => val.value !== 'actions'),
              items: this.listItems,
              itemKey: 'id',
              itemClass: () => 'v-data-table__item',
              itemsPerPage: this.listLimit,
              loading: this.listIsLoading,
              noDataText: 'Нет результатов по данным фильтрам',
              hideDefaultFooter: true,
            },
            scopedSlots: {
              'item.actions': this.renderActions,
              top,
              ...itemNamedSlots,
              ...itemCustomSlots,
            },
          },
          [this.$createElement(Spinner, { slot: 'progress' })],
        );
      },

      renderActions({ item }) {
        const props = {
          ...this.availableActions,
          viewRoute,
          item,
        };

        return this.$createElement(IconActions, {
          props,
          scopedSlots: {
            default: this.$scopedSlots['item.actions'],
            prepend: this.$scopedSlots['item.actions.prepend'],
            append: this.$scopedSlots['item.actions.append'],
          },
        });
      },

      renderPagination() {
        return (
          <VPagination
            v-show={this.listShowPagination}
            value={this.listCurrentPage}
            length={this.listTotalPages}
            totalVisible="10"
            onInput={this.changePagination}
          />
        );
      },

      renderTopSlot() {
        const start = (this.listCurrentPage - 1) * this.listLimit || 1;
        // if we r on last page - show total amount instances
        const end = min([
          this.listCurrentPage * this.listLimit,
          this.listTotal,
        ]);
        const range = `${start}-${end}`;
        let fullString = `${range} из ${this.listTotal} ${messages.resourceName}`;

        // handle occasions when 0 resourses r found
        if (this.listTotal === 0)
          fullString = `Найдено 0 ${messages.resourceName}`;

        const listError = this.$store.getters[storeModule + '/listError'];

        return () => [
          <VCol
            cols="12"
            class="text--disabled text-right pt-2 pb-0 font-italic"
          >
            {listError
              ? ''
              : this.listTotal || this.listTotal === 0
              ? fullString
              : 'Загрузка...'}
          </VCol>,
        ];
      },

      renderFilters() {
        const searchFilter = this.searchFilter ? (
          <VCol cols="12" md="3" sm="4">
            <VTextField
              label={messages.searchFilterLabel}
              placeholder={messages.searchFilterPlaceholder}
              hint={messages.searchFilterHint}
              appendIcon={this.$vuetify.icons.values.search}
              hideDetails="auto"
              readonly={this.listIsLoading}
              clearable={this.listSearch != null}
              value={this.listSearch}
              errorMessages={this.getValidationErrors('listSearch')}
              onBlur={() => this.validateField('listSearch')}
              onInput={this.querySearchList}
              outlined
              dense
            />
          </VCol>
        ) : null;

        const customFilters = this.$scopedSlots['filters']
          ? this.$scopedSlots['filters'](this)
          : null;

        const resetBtn = (searchFilter || customFilters) && (
          <VBtn text onClick={this.resetFilters} disabled={this.listIsLoading}>
            Сбросить
          </VBtn>
        );

        const filters = [searchFilter, customFilters];
        const hasFilters = !!filters.filter(_ => _).length;
        const buttonClass = hasFilters
          ? 'd-flex align-center justify-end flex-grow-1 pa-3'
          : 'd-flex align-center justify-end flex-grow-1';

        const filtersSectionButtons = (
          <div class={buttonClass}>
            {resetBtn}
            <VBtn
              text={!this.needApplyFilters}
              color={this.needApplyFilters ? 'primary' : null}
              onClick={this.updateList}
              disabled={this.listIsLoading}
            >
              {this.needApplyFilters ? 'Применить' : 'Обновить'}
            </VBtn>
          </div>
        );

        return {
          hasFilters,
          filters,
          filterButtons: [filtersSectionButtons],
        };
      },

      // Actions
      openImportModal() {
        const props = {
          chooseOnlyCreate: this.importSettings.chooseOnlyCreate,
          messages: messages.import,
          entity: entityForImport,
          linkToImportTemplate:
            this.$store.getters[storeModule + '/importTemplateURL'],
          onSubmit: data =>
            this.$store.dispatch(storeModule + '/importList', data),
        };

        if (this.$listeners['item:import'])
          return this.$listeners['item:import'](props);

        this.$openModal(
          () => import('@/components/crud/ImportModal.vue'),
          props,
          {},
          {
            default: this.$scopedSlots['import'],
            prepend: this.$scopedSlots['import.prepend'],
            append: this.$scopedSlots['import.append'],
          },
        );
      },

      openGoByIdModal() {
        if (this.$listeners['item:goById'])
          return this.$listeners['item:goById']();

        const props = {
          messages: messages.goById,
          onSubmit: id => {
            if (this.$listeners['item:show'])
              return this.$listeners['item:show']({ id });
          },
        };

        this.$openModal(
          () => import('@/components/crud/GoByIdModal.vue'),
          props,
        );
      },

      async exportData() {
        this.$notify({
          group: 'note',
          type: 'info',
          title: 'Подготовка результатов к экспорту...',
        });

        try {
          const result = await this.$store.dispatch(
            storeModule + '/exportList',
          );
          window.open(result.url, '_blank');
          this.$notify({
            group: 'note',
            type: 'info',
            title: 'Результаты успешно экспортированы',
          });
          this.$emit('close');
        } catch (err) {
          const errMessage = isXhrError(err)
            ? xhrErrorMessage(err)
            : err.message;
          this.$notify({
            group: 'note',
            type: 'error',
            title: 'Не получилось экспортировать результаты',
            text: errMessage,
          });
        }
      },

      resetFilters() {
        // При наличии уникальной логики сброса фильтров - выполнить ее
        if (this.$listeners['filters:reset'])
          return this.$listeners['filters:reset']();

        // to not bother making 'updateList' or 'fetchList' available in all
        // lists where we'll need those there is a 'hook' event. If you pass
        // function to this hook it will do it's sideeffects and proceed with
        // list refreshing
        if (this.$listeners['filters:resetHook'])
          this.$listeners['filters:resetHook']();

        this.$store.dispatch(storeModule + '/resetListFilters');
        this.updateList();
      },

      updateList() {
        if (this.$listeners['filters:searchHook'])
          this.$listeners['filters:searchHook'](this.listQuery);
        // Обновление списка через единый flow получения данных в компонент
        this.fetchList(this.listQuery);
      },
    },

    render() {
      const { hasFilters, filters, filterButtons } = this.renderFilters();

      return this.$createElement(
        embedded ? EmbeddedListLayout : ListPageLayout,
        {
          props: {
            title: 'Список ' + messages.resourceName,
            hasFilters,
          },
          scopedSlots: {
            titleActions: this.renderTitleActions,
            list: this.renderList,
            footer: this.renderPagination,
            filters: () => filters,
            filterButtons: () => filterButtons,
            actions: this.$scopedSlots['actions'],
          },
        },
      );
    },
  });
};
