import integrationsApi from '@/api/services/integrations';
import structuresApi from '@/api/services/structures';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';
import { filters } from '../entity';
import { changePages } from '@/utils/vuex/changePages';
import { currentDateTime } from '@/utils/helpers';
import { getUserTimeZone } from '@/utils/convert';

const state = {
  softwares: [],
  datePlaceholder: null,
};

const getters = {
  softwares: state => state.softwares,
  datePlaceholder: state => state.datePlaceholder,
};

const mutations = {
  softwares: (state, value) => (state.softwares = value),
  datePlaceholder: (state, value) => (state.datePlaceholder = value),
};

const actions = {
  async fetchList({ commit, getters }, query) {
    await changePages(getters, commit, query);

    const params = { ...getters.listQuery };

    // Задаем дефолтное значение на выборку за последние 24 часа
    if (!params?.dateStart && !params?.dateEnd) {
      const dateWithZone = currentDateTime().setZone(getUserTimeZone() * 60, {
        keepLocalTime: true,
      });
      params.dateStart = dateWithZone.minus({ days: 1 }).toISO();
      params.dateEnd = dateWithZone.toISO();

      commit('datePlaceholder', {
        dateStart: params.dateStart,
        dateEnd: params.dateEnd,
      });
    } else if (getters.datePlaceholder !== null) {
      commit('datePlaceholder', null);
    }

    try {
      commit('listFetching');
      const response = await integrationsApi.getNiiatInspections(params);
      commit('listFetched', response);
    } catch (error) {
      commit('listErrorFetched', error);
      throw error;
    }
  },
  async fetchSoftwares({ commit }) {
    const result = await structuresApi.getSoftwares();
    commit('softwares', result);
  },

  setDateFilters({ commit }, { dateStart, dateEnd }) {
    commit('dateStart', dateStart);
    commit('dateEnd', dateEnd);
  },
};

export default createModule(
  crudListFactory({
    filters,
  }),
  { state, getters, mutations, actions },
);
