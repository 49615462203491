import { ModuleDefinition } from '@/models/app/ModuleDefinition';
import EMPLOYEE from './store/item';
import EMPLOYEE_LIST from './store/list';

import routes from './routes';

export default {
  routes,
  store: {
    EMPLOYEE,
    EMPLOYEE_LIST,
  },
  menu: [
    {
      title: 'Работники',
      action: 'EMP_R',
      name: 'employees',
      priority: 2,
    },
  ],
  modals: [
    {
      name: 'employees/form',
      component: () => import('@/modules/employees/modals/Form.vue'),
    },
    {
      name: 'employees/archivePhoto',
      component: () => import('@/modules/employees/modals/ArchivePhoto.vue'),
    },
    {
      name: 'employees/uploadPhoto',
      component: () => import('@/modules/employees/modals/UploadPhoto.vue'),
    },
    {
      name: 'employees/medrecCharts',
      component: () => import('@/modules/employees/modals/ShowCharts.vue'),
    },
    {
      name: 'employees/unban',
      component: () => import('@/modules/employees/modals/Unban.vue'),
    },
    {
      name: 'employees/editExtras',
      component: () => import('@/modules/employees/modals/EditExtras.vue'),
    },
    {
      name: 'employees/medicalTests',
      component: () => import('@/modules/employees/modals/MedicalTests.vue'),
    },
    {
      name: 'employees/medicalTest',
      component: () => import('@/modules/employees/modals/MedicalTest.vue'),
    },
    {
      name: 'employees/deleteMedicalTest',
      component: () =>
        import('@/modules/employees/modals/DeleteMedicalTest.vue'),
    },
    {
      name: 'employees/updatePhone',
      component: () => import('@/modules/employees/modals/PhoneForm.vue'),
    },
    {
      name: 'employees/profEvents',
      component: () =>
        import('@/modules/employees/modals/ProphylacticEvents.vue'),
    },
    {
      name: 'employees/medicalPrevention',
      component: () =>
        import('@/modules/employees/modals/MedicalPrevention.vue'),
    },
  ],
} as ModuleDefinition;
