import env from '@/plugins/env';
import { DateTime } from '@/plugins/luxon';

export const ACTIVE_SIGNATURE_TOKEN_KEY = 'active_signature_token';
export const ACCESS_TOKEN_KEY = 'a_access';
export const REFRESH_TOKEN_KEY = 'a_refresh';
export const IGNORE_BROWSER_VERSION = 'ignore_browser_version';
export const DARK_THEME_KEY = 'dark_theme';
export const NAME_ROOT_ROUTE = 'main';

export const GENDER = {
  MALE: 'Мужской',
  FEMALE: 'Женский',
  undefined: 'Мужской',
};
export const GENDER_NOMINATIVE = {
  MALE: 'Мужчина',
  FEMALE: 'Женщина',
  undefined: 'Мужчина',
};

export const STATUS = [
  { name: 'Все статусы', value: null },
  { name: 'Активные', value: true },
  { name: 'Неактивные', value: false },
];

export const BOOLEANS = [
  { name: 'Неважно', value: null },
  { name: 'Да', value: true },
  { name: 'Нет', value: false },
];

// --- INSPECTIONS ---

export const REMARK_TYPES = {
  MEDICAL: 'Медицинские',
  ADMINISTRATIVE: 'Административные',
  TECHNICAL: 'Технические',
  ALCOHOL: 'Алкоголь',
  FRAUD: 'Мошенничество',
};

/**
 * Палетка цветов.
 * Специально задана в RGB, чтобы удобно задавать прозрачность.
 */
export const COLORS = {
  green: '36,166,69', // #24A645
  orange: '245,124,0', // #F57C00
  red: '255,86,78', // #FF564E
  purple: '163,147,245', // #A393F5
  violet: '204, 0, 255', // #CC00FF
  gray: '166,166,166', // #A6A6A6
  blue: '33,150,243', // #2196F3
  yellow: '251,192,45', // #FBC02D
};

// --- VALIDATIONS ---

export const VALIDATION_RULE_MESSAGES = {
  // sorted in alphabetical order for ease of perception
  contact: 'Контакт не валиден',
  cyrillicName: 'Только кириллица, с большой буквы',
  date: 'Дата не валидна',
  decimal: 'Только число',
  email: 'Только валидная почта',
  fileCsv: 'Только ".csv"',
  fileName: 'Только латиница, кириллица, цифры, _ и -',
  hostLicense:
    'Лицензия должна иметь формат "A270920181900-XXXXX", где X - цифра',
  integer: 'Только целое число',
  kpp: 'Должно состоять из 9 цифр',
  longUniqueStringKey:
    'От 3 до 50 символов, может включать в себя только символы 0-9, a-z (в нижнем регистре), _ и -',
  maxAge: 'Возраст не больше $max лет',
  maxSymbolsLength: 'Не больше $max символов',
  maxValue: 'Не больше, чем $max',
  minAge: 'Возраст не меньше $min лет',
  minSymbolsLength: 'Не менее $min символов',
  minValue: 'Не меньше, чем $min',
  numeric: 'Только целое число',
  phone: 'Телефон в формате +7 (000) 000-00-00',
  required: 'Обязательно',
  sameOrgChosen: 'Нельзя выбрать редактируемую организацию',
  snils: 'Должно состоять из 11 цифр',
  time: 'Укажите время цифрами в диапазоне от 00:00 до 23:59',
  timeScheduler: 'Укажите время цифрами в диапазоне от 00:00 до 23:50',
  uniqueAccount: 'Данный получатель уже выбран',
  uniqueStringKey:
    'От 3 до 15 символов, может включать в себя только символы 0-9, a-z (в нижнем регистре), _ и -',
  url: 'Введите валидный URL',
};

export const USER_GUIDE_URL = {
  terminal: null,
  dispatcher: null,
};

export const TOKEN_TYPES = {
  CRYPTOPRO: 'cryptopro',
  CRYPTOPROTEST: 'cryptoprotest',
  ...(!env.get('VUE_APP_SIGNME_DISABLE') && { SIGNME: 'signme' }),
};

export const TOKEN_TYPES_LANG = {
  CRYPTOPRO: 'КриптоПро',
  CRYPTOPROTEST: 'КриптоПро (Тестовый)',
  ...(!env.get('VUE_APP_SIGNME_DISABLE') && { SIGNME: 'Sign.Me' }),
};

export const SUPPORT_TOPICS_TYPES = {
  INSPECTION: 'inspection',
  GENERAL: 'general',
};

export const HTTP_CODES = {
  204: 'Вернулся пустой объект',
  400: 'Ошибка при выполнении операции',
  401: 'Пользователь не авторизован',
  403: 'Доступ запрещен',
  404: 'Не найдено',
  408: 'Превышено время выполнения запроса',
  409: 'При выполнении запроса произошел конфликт',
  413: 'Превышение лимита объема данных',
  415: 'Неподдерживаемый тип файлов',
  429: 'Слишком много запросов',
  500: 'Непредвиденная ошибка сервера',
  502: 'Удаленный сервис недоступен',
  504: 'Превышено время запроса к сервису',
};

export const APP_FATAL_ERROR_MESSAGE =
  'При выполнении действия произошла ошибка приложения';

export const RECIPIENT_TYPES = [
  {
    key: 'sms',
    label: 'SMS',
    hideInFilter: true,
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'slack',
    label: 'Slack',
  },
  {
    key: 'telegram',
    label: 'Telegram',
  },
];

// quick options in daterangepicker
export const SHORTCUTS = [
  {
    text: 'Сегодня',
    value: [
      DateTime.local().startOf('day').toISO(),
      DateTime.local().endOf('day').toISO(),
    ],
  },
  {
    text: 'Вчера',
    value: [
      DateTime.local().minus({ days: 1 }).startOf('day').toISO(),
      DateTime.local().minus({ days: 1 }).endOf('day').toISO(),
    ],
  },
  {
    text: 'Текущая неделя',
    value: [
      DateTime.local().startOf('week').toISO(),
      DateTime.local().endOf('day').toISO(),
    ],
  },
  {
    text: 'Прошлая неделя',
    value: [
      DateTime.local().minus({ weeks: 1 }).startOf('week').toISO(),
      DateTime.local().minus({ weeks: 1 }).endOf('week').toISO(),
    ],
  },
  {
    text: 'Последние 2 недели',
    value: [
      DateTime.local().minus({ weeks: 2 }).startOf('day').toISO(),
      DateTime.local().endOf('day').toISO(),
    ],
  },
  {
    text: 'Текущий месяц',
    value: [
      DateTime.local().startOf('month').toISO(),
      DateTime.local().endOf('day').toISO(),
    ],
  },
  {
    text: 'Прошлый месяц',
    value: [
      DateTime.local().minus({ month: 1 }).startOf('month').toISO(),
      DateTime.local().minus({ month: 1 }).endOf('month').toISO(),
    ],
  },
];

export const RU_TIMEZONES = [
  { value: 2, text: 'GMT+2, Калининград' },
  { value: 3, text: 'GMT+3, Москва' },
  { value: 4, text: 'GMT+4, Самара, Астрахань' },
  { value: 5, text: 'GMT+5, Челябинск, Екатеринбург' },
  { value: 6, text: 'GMT+6, Омск' },
  { value: 7, text: 'GMT+7, Красноярск, Новосибирск, Норильск' },
  { value: 8, text: 'GMT+8, Иркутск' },
  { value: 9, text: 'GMT+9, Якутск, Чита' },
  { value: 10, text: 'GMT+10, Владивосток, Хабаровск' },
  { value: 11, text: 'GMT+11, Магадан' },
  { value: 12, text: 'GMT+12, п-ов Камчатка, Петропавловск-Камчатский' },
];

export const RU_TIMEZONES_MINUTES = [
  { value: 120, text: 'GMT+2, Калининград' },
  { value: 180, text: 'GMT+3, Москва' },
  { value: 240, text: 'GMT+4, Самара, Астрахань' },
  { value: 300, text: 'GMT+5, Челябинск, Екатеринбург' },
  { value: 360, text: 'GMT+6, Омск' },
  { value: 420, text: 'GMT+7, Красноярск, Новосибирск, Норильск' },
  { value: 480, text: 'GMT+8, Иркутск' },
  { value: 540, text: 'GMT+9, Якутск, Чита' },
  { value: 600, text: 'GMT+10, Владивосток, Хабаровск' },
  { value: 660, text: 'GMT+11, Магадан' },
  { value: 720, text: 'GMT+12, п-ов Камчатка, Петропавловск-Камчатский' },
];

export const DISTRIBUTION_TYPES = {
  active: 'Стандартный',
  passive: 'Альтернативный',
  bot: 'Обработан ботом',
};

export const FETCH_STATUS = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  SUCCEEDED: 'SUCCEEDED',
  CANCELLED: 'CANCELLED',
  ERROR: 'ERROR',
};

// FIXME:(surveys) этот костыль уйдет, если бэк будет присылать русифицированные формулировки
// Мапинг вопросов в опросниках работника
export const QUESTIONS = {
  body_height: 'Укажите Ваш текущий рост в см.',
  body_weight: 'Укажите Ваш текущий вес в кг.',
  do_you_smoke: 'Курите ли Вы?',
  smoking_experience: 'Сколько полных лет Вы курите?',
  cigaretts_amount: 'Сколько сигарет Вы выкуриваете в день?',
};

export const AUTH_TYPES = {
  personnell_number: 'Табельный номер',
  faceid: 'Распознавание по лицу',
  faceid_and_pn: 'Распознавание по лицу и табельному номеру',
  faceid_or_pn: 'Распознавание по лицу или табельному номеру',
  phone_pin: 'По номеру телефона',
  rfid: 'По карте RFID',
  smart_card: 'По смарт-карте',
};

export const ANSWERS_STATUS = {
  discarded: 'Инвалидирован',
  expired: 'Просрочен',
};

// FIXME:(surveys) этот костыль уйдет, когда на бэке организуют иерархичность вопросов
// Зависимость вопросов при инвалидации
export const CRUTCH_HIERARCHY_QUESTIONS_MAP = {
  do_you_smoke: ['smoking_experience', 'cigaretts_amount'],
};

// Раскрасска событий процессинга
export const COLOR_BY_EVENT_TYPE = {
  timeout: {
    color: 'blue-grey',
    lightness: 4,
  },
  rejected: {
    color: 'red',
    lightness: 4,
  },
  resolved: {
    color: 'green',
    lightness: 4,
  },
  released: {
    color: 'yellow',
    lightness: 4,
  },
  unintentional_reject: {
    color: 'yellow',
    lightness: 2,
  },
  error_in_progress: {
    color: 'yellow',
    lightness: 2,
  },
};

export const CONFIG_TYPES = [
  { name: 'По умолчанию', value: false },
  { name: 'Настраиваемый', value: true },
  { name: 'Все виды', value: null },
];

export const LEGAL_FORM = [
  { name: 'Юридическое лицо', id: 'llc' },
  { name: 'Индивидуальный предприниматель', id: 'self_employed_llc' },
  { name: 'Самозанятый', id: 'self_employed' },
];

export const LEGAL_FORM_FILTER = [{ name: 'Неважно', id: null }, ...LEGAL_FORM];

export const OGRN_TITLE = {
  llc: 'ОГРН',
  self_employed_llc: 'ОГРНИП',
};

export const NIIAT_INSPECTIONS_STATUS = [
  { name: 'Неважно', value: null },
  { name: 'В очереди на отправку ', value: 'waiting' },
  { name: 'Все ошибки', value: 'any_error' },
  { name: 'Внутренняя ошибка', value: 'internal_error' },
  { name: 'Внешняя ошибка', value: 'external_error' },
  { name: 'Осмотр зарегистрирован', value: 'success' },
];

export const EWB_TYPES = [
  { name: 'Титул Т1', value: 'Т1ЭПЛ' },
  { name: 'Титул Т2', value: 'Т2ЭПЛ' },
  { name: 'Т2_ОТКАЗ', value: 'Т2ЭПЛ_ОТКАЗ' },
  { name: 'Титул Т5', value: 'Т5ЭПЛ' },
  { name: 'Титул Т6', value: 'Т6ЭПЛ' },
];
