<script>
import { VTextField } from 'vuetify/lib';
import IMask from 'imask';
import debounce from 'lodash/debounce';

export default {
  props: {
    mask: { required: true, type: Object },
    value: { type: [String, Number], default: '' },
    textCenter: Boolean,
    firstFormat: Boolean,
  },

  data: () => ({
    inputEl: null,
    masked: null,
  }),

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.setValue(val);
      },
    },
    mask() {
      if (this.masked) {
        this.masked.off('accept', this.onChange);
        this.masked.destroy();
      }

      this.init();
    },
  },

  mounted() {
    this.init();
    if (this.firstFormat) this.onChange();
  },

  beforeDestroy() {
    if (this.masked) {
      this.masked.off('accept', this.onChange);
      this.masked.destroy();
    }
  },

  methods: {
    debounceUpdateInput: debounce.call(
      this,
      function () {
        this.inputEl.value = this.masked.value;
      },
      100,
    ),

    setValue(val) {
      if (this.masked) {
        this.masked.value = val;
        this.masked.updateValue();
      }
    },

    init() {
      this.inputEl = this.$refs.field.$el.querySelector('input');

      this.masked = IMask(this.inputEl, this.mask);
      this.masked.on('accept', this.onChange);
      // Set mounted value
      this.masked.updateValue();
      // Re-render input field (fucking crutch)
      this.inputEl.dispatchEvent(new Event('input'));
    },

    onChange() {
      this.$emit('input', this.masked.value);
    },

    checkNormalize() {
      // NOTE: v-text-field stores the last character outside of the mask
      // in field "value". At the next focus event, it draws it, and since
      // the field was changed outside IMask, it refuses to work.
      const isNormalized = this.inputEl.value === this.masked.value;
      if (!isNormalized) {
        this.inputEl.value = this.masked.value;
        // Event to v-text-field
        this.inputEl.dispatchEvent(new Event('input'));
      }
    },

    getUnmaskedValue() {
      return this.masked.unmaskedValue;
    },
  },

  render(createElement) {
    return createElement(VTextField, {
      attrs: this.$attrs,
      ref: 'field',
      props: { value: this.value },
      on: {
        ...this.$listeners,
        input: () => {
          // Input из $listeners не пробрасывается, потому что выполняется
          // emit('input',...) при событии изменения значения маски
          this.debounceUpdateInput();
        },
        'click:clear': () => {
          this.setValue('');
        },
        blur: ev => {
          this.checkNormalize(ev);
          if (this.$listeners.blur) this.$listeners.blur(ev);
        },
      },
    });
  },
};
</script>
