import plural from 'plural-ru';
import { DateTime } from '@/plugins/luxon';
import { calculateDuration } from './helpers';

export function clearString(string) {
  return string.replace(/[\s{2,}|\\n|\\t]/g, ' ').trim();
}

export function name(value) {
  // бэкэнд вещь не консистентная и не постоянная..
  return `${value?.surname ?? value?.lastName ?? ''} ${
    value?.name ?? value?.firstName ?? ''
  } ${value?.patronymic ?? ''}`
    .replace(/\s{2,}/, ' ')
    .trim();
}

export function shortName(value) {
  return [
    value?.surname,
    value?.name ? `${value.name[0]}.` : null,
    value?.patronymic ? `${value.patronymic[0]}.` : null,
  ]
    .filter(a => a)
    .join(' ');
}

export function gender(value) {
  return value === 'FEMALE' ? 'Женский' : 'Мужской';
}

export function dateTime(
  value,
  {
    setZone = false,
    timezone = null,
    showTimeZone = false,
    showSeconds = false,
    showMilliseconds = false, // Работает только с "showSeconds"
    format = null,
    round = false,
  } = {},
) {
  if (!value) return '';
  let dt = DateTime.fromISO(value, { setZone });
  if (round && !showMilliseconds) {
    dt = dt.set({ millisecond: dt.get('millisecond') + 500 });
  }
  if (timezone !== null) dt = dt.setZone(timezone * 60);
  const formatInner =
    format ||
    (
      'dd.LL.yy, ' +
      (showSeconds ? (showMilliseconds ? 'TT.SSS' : 'TT') : 'T') +
      (showTimeZone ? '(Z)' : '')
    ).trim();
  return dt.isValid ? dt.toFormat(formatInner) : '–';
}

export function date(value) {
  if (!value) return '';
  const dt = DateTime.fromISO(value);
  return dt.isValid ? dt.toFormat('dd.MM.y') : '–';
}

export function dateLang(value) {
  if (!value) return '';
  const dt = DateTime.fromISO(value);
  return dt.isValid ? dt.toFormat('dd.LL.y') : '–';
}

export function getTime(value) {
  if (!value) return '';
  const dt = DateTime.fromISO(value);
  return dt.isValid ? dt.toFormat('TT') : '–';
}

export function getUserTimeZone() {
  const offset = DateTime.local().offset;
  return offset / 60;
}

export function duration(start, end) {
  const diffObject = calculateDuration(start, end);

  // milliseconds aren't used anywhere, but we need to round up seconds
  diffObject.milliseconds > 500 && diffObject.seconds++;

  if (diffObject.seconds === 60) {
    diffObject.minutes++;
    diffObject.seconds = 0;
  }

  let diffString = '';
  if (diffObject.months)
    diffString += `${diffObject.months} ${plural(
      diffObject.months,
      'месяц',
      'месяца',
      'месяцев',
    )}`;
  if (diffObject.days)
    diffString += ` ${diffObject.days} ${plural(
      diffObject.days,
      'день',
      'дня',
      'дней',
    )}`;
  if (diffObject.hours)
    diffString += ` ${diffObject.hours} ${plural(
      diffObject.hours,
      'час',
      'часа',
      'часов',
    )}`;
  if (diffObject.minutes)
    diffString += ` ${diffObject.minutes} ${plural(
      diffObject.minutes,
      'минута',
      'минуты',
      'минут',
    )}`;
  if (diffObject.seconds)
    diffString += ` ${diffObject.seconds} ${plural(
      diffObject.seconds,
      'секунда',
      'секунды',
      'секунд',
    )}`;

  return diffString.trim();
}

export function durationInMinutes(start, end) {
  const diffObject = calculateDuration(start, end);

  return (
    String(diffObject.minutes).padStart('2', '0') +
    ':' +
    String(diffObject.seconds).padStart('2', '0')
  );
}

export function secondsToWordTime(seconds = 0) {
  const time = DateTime.fromSeconds(seconds);
  const totalTime = duration(DateTime.fromSeconds(0), time);
  return totalTime;
}

export function timeToObject(dtString) {
  const time = dtString || '00:00';
  const [hour = '00', minute = '00'] = time.split(':');
  return { hour: hour.padStart(2, '0'), minute: minute.padEnd(1, '0') };
}

export function setZoneWithoutChangeTime(dt, offset) {
  // set zone from date time
  return (
    DateTime.fromISO(dt, { setZone: true })
      // change to zone from offset, without change time (only timezone)
      .setZone(offset, { keepLocalTime: true })
  );
}
