import {
  required,
  requiredIf,
  maxValue,
  minValue,
} from 'vuelidate/lib/validators';
import { ReportSchemes } from '../models';
import { MOSCOW_TIMEZONE } from '../constants';
import store from '@/store';

// object to not duplicate risk-groups report object
const riskGroupsObject = {
  fields: [
    {
      is: () => store.getters['AUTH/isRoot'],
      preset: 'Organization',
      validation: { required },
    },
    {
      is: () => !store.getters['AUTH/isRoot'],
      preset: 'RiskAvailableOrgs',
      validation: { required },
    },
    {
      preset: 'DateRange',
      props: {
        onlyMonth: true,
      },
      validation: { required },
    },
    {
      preset: 'Checkbox',
      name: 'displayNotAtRiskPeoples',
      defaultValue: false,
      props: {
        label: 'Учитывать всех работников',
      },
      validation: { required },
    },
  ],
  fixedValues: {
    timeDisplayMode: 'system',
    timezone: MOSCOW_TIMEZONE,
  },
};

export const schemes: ReportSchemes = {
  // ************************
  // ** Отчеты по осмотрам **
  // ************************
  'universal-inspections': {
    fields: [
      {
        preset: 'Choose',
        name: 'type',
        props: {
          options: [
            { value: 'byOrgGroup', name: 'По группе организаций' },
            { value: 'byOrg', name: 'По организации' },
          ],
          label: 'Выберите способ формирования:',
        },
        defaultValue: context => (context.value.orgId ? 'byOrg' : 'byOrgGroup'),
        cols: 12,
        watch: context =>
          context.$emit('input', {
            ...context.value,
            orgGroupId: undefined,
            orgId: undefined,
            pointIds: undefined,
            hostGroupIds: undefined,
            employeeGroupIds: undefined,
            hostIds: undefined,
            includeActivePoints: false,
          }),
      },
      {
        is: context => context.value.type === 'byOrgGroup',
        preset: 'OrganizationGroup',
        validation: { required },
        cols: 12,
      },
      {
        is: context => context.value.type === 'byOrg',
        preset: 'Organization',
        validation: { required },
        watch: context =>
          context.$emit('input', {
            ...context.value,
            pointIds: undefined,
            employeeGroupIds: undefined,
            hostGroupIds: undefined,
            hostIds: undefined,
          }),
        cols: 12,
      },
      {
        is: context => context.value.type === 'byOrg',
        preset: 'Points',
        props: value => ({
          disabled: !value.orgId || value.includeActivePoints,
          orgIds: value.orgId ? [value.orgId] : null,
        }),
      },
      {
        is: context => context.noBatch && context.value.type === 'byOrg',
        preset: 'IncludeActivePoints',
        watch(context) {
          // Сбросить выбранные точки
          context.value.pointIds = context.value.includeActivePoints
            ? undefined
            : [];
          return context;
        },
      },
      {
        is: context => context.value.type === 'byOrg',
        preset: 'EmployeeGroups',
        props: value => ({
          disabled: !value.orgId,
          orgIds: value.orgId ? [value.orgId] : null,
        }),
      },
      {
        is: context => context.value.type === 'byOrg',
        preset: 'HostGroups',
        props: value => ({
          disabled: !value.orgId,
          orgIds: value.orgId ? [value.orgId] : null,
          type: 'all',
        }),
      },
      {
        is: context => context.value.type === 'byOrg',
        preset: 'Hosts',
        props: value => ({
          disabled: !value.orgId,
          orgIds: value.orgId ? [value.orgId] : null,
          groupIds: value.hostGroupIds ? [...value.hostGroupIds] : null,
          releasePointIds: value.pointIds ? [...value.pointIds] : null,
          isActive: value.includeActivePoints || null,
        }),
      },

      {
        preset: 'DateRange',
        validation: { required },
      },
      {
        preset: 'Timezone',
        validation: { required },
        cols: 3,
      },
      {
        preset: 'TimeDisplayMode',
        validation: { required },
        cols: 3,
      },
    ],
  },
  'inspections-detailed': {
    fields: [
      {
        preset: 'Choose',
        name: 'type',
        props: {
          options: [
            { value: 'byOrgGroup', name: 'По группе организаций' },
            { value: 'byOrg', name: 'По организации' },
          ],
          label: 'Выберите способ формирования:',
        },
        defaultValue: context => (context.value.orgId ? 'byOrg' : 'byOrgGroup'),
        cols: 12,
        watch: context =>
          context.$emit('input', {
            ...context.value,
            orgGroupId: undefined,
            orgId: undefined,
          }),
      },
      {
        is: context => context.value.type === 'byOrgGroup',
        preset: 'OrganizationGroup',
        validation: { required },
        cols: 12,
      },
      {
        is: context => context.value.type === 'byOrg',
        preset: 'Organization',
        validation: { required },
        cols: 12,
      },
      {
        preset: 'DateRange',
        validation: { required },
      },
      {
        preset: 'Timezone',
        validation: { required },
        cols: 3,
      },
      {
        preset: 'TimeDisplayMode',
        validation: { required },
        cols: 3,
      },
      {
        preset: 'Checkbox',
        name: 'includeTechnicalErrors',
        defaultValue: false,
        props: {
          label: 'Учитывать осмотры с техническими ошибками',
        },
        cols: 12,
      },
      {
        preset: 'IncludeTestCheckbox',
        validation: { required },
        cols: 12,
      },
      {
        preset: 'IncludeStepsCheckbox',
        validation: { required },
        cols: 12,
      },
    ],
  },

  // *******************
  // ** Прочие отчеты **
  // *******************
  'rest-working-hours': {
    fields: [
      {
        preset: 'Organizations',
        validation: { required },
      },
      {
        preset: 'HoursRestMinutes',
        validation: { required, maxValue: maxValue(24), minValue: minValue(1) },
        cols: 6,
      },
      {
        preset: 'Date',
        validation: { required },
        cols: 8,
      },
      {
        is: context => !context.noTimezone,
        preset: 'Timezone',
        validation: { required },
        cols: 4,
      },
    ],
    fixedValues: {
      timeDisplayMode: 'user',
    },
  },
  'banned-employees': {
    fields: [
      {
        preset: 'Organization',
        validation: { required },
        watch: context =>
          context.$emit('input', {
            ...context.value,
            pointIds: undefined,
            pointId: undefined,
          }),
      },
      {
        is: context => context.noBatch,
        preset: 'Point',
        props: value => ({
          disabled: !value.orgId || value.includeActivePoints,
          orgIds: value.orgId ? [value.orgId] : null,
        }),
        validation: {
          required: requiredIf(values => !values.includeActivePoints),
        },
      },
      {
        is: context => !context.noBatch,
        preset: 'Points',
        props: value => ({
          disabled: !value.orgId,
          orgIds: value.orgId ? [value.orgId] : null,
        }),
        validation: { required },
      },
      {
        preset: 'DateRange',
        validation: { required },
      },
      {
        is: context => context.noBatch,
        preset: 'IncludeActivePoints',
        watch(context) {
          // Сбросить выбранную точку
          context.value.pointId = undefined;
          return context;
        },
      },
      {
        preset: 'Timezone',
        validation: { required },
        cols: 3,
      },
      {
        preset: 'TimeDisplayMode',
        validation: { required },
        cols: 3,
      },
      {
        preset: 'Choose',
        name: 'type',
        props: {
          options: [
            { value: 'default', name: 'По умолчанию' },
            { value: 'detailed', name: 'Детализированный' },
          ],
          label: 'Тип отчета:',
        },
        defaultValue: () => 'default',
        cols: 12,
      },
    ],
    batchByField: 'pointIds',
    batchedFieldName: 'pointId',
  },
  'outgoing-docs': {
    fields: [
      {
        preset: 'Organization',
        validation: { required },
        watch: context =>
          context.$emit('input', {
            ...context.value,
            pointIds: undefined,
            pointId: undefined,
          }),
      },
      {
        is: context => context.noBatch,
        preset: 'Point',
        props: value => ({
          disabled: !value.orgId || value.includeActivePoints,
          orgIds: value.orgId ? [value.orgId] : null,
        }),
        validation: {
          required: requiredIf(values => !values.includeActivePoints),
        },
      },
      {
        is: context => !context.noBatch,
        preset: 'Points',
        props: value => ({
          disabled: !value.orgId,
          orgIds: value.orgId ? [value.orgId] : null,
        }),
        validation: { required },
      },
      {
        preset: 'DateRange',
        validation: { required },
      },
      {
        is: context => context.noBatch,
        preset: 'IncludeActivePoints',
        watch(context) {
          // Сбросить выбранную точку
          context.value.pointId = undefined;
          return context;
        },
      },
      {
        preset: 'Timezone',
        validation: { required },
      },
    ],
    fixedValues: {
      timeDisplayMode: 'system',
    },
    batchByField: 'pointIds',
    batchedFieldName: 'pointId',
    reportType: values => (values.includeActivePoints ? 'composite' : 'file'),
  },
  'user-actions-log': {
    fields: [
      {
        preset: 'Organizations',
        validation: { required },
        cols: 12,
      },
      {
        preset: 'DateRange',
        validation: { required },
        cols: 8,
      },
      {
        preset: 'Timezone',
        validation: { required },
        cols: 4,
      },
    ],
    fixedValues: {
      timeDisplayMode: 'user',
    },
  },
  'billing-summary': {
    fields: [
      {
        preset: 'Organizations',
        validation: {
          required: requiredIf(values => !values.setAllOrgs),
        },
        props: values => (values.setAllOrgs ? { disabled: true } : {}),
        prepareValue(name, value, filters) {
          return filters.setAllOrgs ? undefined : [name, value];
        },
      },
      {
        preset: 'DateRange',
        validation: { required },
      },
      {
        preset: 'SetAllOrgs',
        watch(context) {
          // Сбросить выбранные организации
          if (context.value.setAllOrgs) context.value.orgIds = [];
          return context;
        },
        // Не передаем на бэк
        prepareValue: () => undefined,
      },
      {
        preset: 'ReportType',
        validation: { required },
      },
    ],
    fixedValues: {
      timezone: MOSCOW_TIMEZONE,
      timeDisplayMode: 'system',
    },
  },
  'billing-summary-new': {
    fields: [
      {
        preset: 'Choose',
        name: 'byOrder',
        props: {
          options: [
            { value: 'byOrgGroup', name: 'По группе организаций' },
            { value: 'byOrg', name: 'По организации' },
          ],
          label: 'Выберите способ формирования:',
        },
        defaultValue: context => (context.value.orgId ? 'byOrg' : 'byOrgGroup'),
        cols: 12,
        watch: context =>
          context.$emit('input', {
            ...context.value,
            orgGroupIds: undefined,
            orgIds: undefined,
            pointIds: undefined,
            hostGroupIds: undefined,
            employeeGroupIds: undefined,
            hostIds: undefined,
            includeActivePoints: false,
          }),
      },
      {
        is: context => context.value.byOrder === 'byOrgGroup',
        preset: 'OrganizationGroupMultiple',
        validation: { required },
      },
      {
        preset: 'Organizations',
        is: context => context.value.byOrder === 'byOrg',
        validation: {
          required: requiredIf(values => !values.setAllOrgs),
        },
        props: values => (values.setAllOrgs ? { disabled: true } : {}),
        prepareValue(name, value, filters) {
          return filters.setAllOrgs || filters.byOrder === 'byOrgGroup'
            ? undefined
            : [name, value];
        },
      },
      {
        preset: 'DateRange',
        validation: { required },
      },
      {
        preset: 'SetAllOrgs',
        watch(context) {
          // Сбросить выбранные организации
          if (context.value.setAllOrgs) context.value.orgIds = [];
          return context;
        },
        // Не передаем на бэк
        prepareValue: () => undefined,
      },
      {
        preset: 'ReportType',
        props: {
          cancelledName: 'С осмотрами от бота',
        },
        validation: { required },
      },
    ],
    fixedValues: {
      timezone: MOSCOW_TIMEZONE,
      timeDisplayMode: 'system',
    },
  },
  'risk-groups': riskGroupsObject,
  'risk-groups-new': riskGroupsObject,
  stickers: {
    fields: [
      { preset: 'Organization', validation: { required } },
      { preset: 'DateRange', validation: { required } },
    ],
    formats: ['pdf'],
    reportType: () => 'composite',
  },
  'list-contingents': {
    fields: [
      { preset: 'Organization', validation: { required } },
      {
        preset: 'EmployeeGroups',
        props: value => ({
          disabled: !value.orgId,
          orgIds: value.orgId ? [value.orgId] : null,
        }),
      },
    ],
  },
};

export default schemes;
