// api
import processingApi from '@/api/services/processing';
import medrecApi from '@/api/services/medrec';
import integrationsApi from '@/api/services/integrations';
import authApi from '@/api/services/auth';
import schedulerApi from '@/api/services/scheduler';
import scenariosApi from '@/api/services/scenarios';
import gateApi from '@/api/services/gate';

import { createModule } from '@/utils/vuex/createModule';
import { itemFactory } from '@/utils/vuex/itemFactory';

export default createModule(
  /**
   * Get types of incoming documents (medrec-service)
   *
   * action: fetchIncomingDocumentTypes
   * getter: incomingDocumentTypes
   */
  itemFactory({
    name: 'incomingDocumentTypes',
    primaryKey: 'key',
    fetchMethod: () => medrecApi.getDocumentTypes({ limit: 10000, page: 1 }),
  }),

  /**
   * Get types of outgoing documents (medrec-service)
   *
   * action: fetchOutgoingDocumentTypes
   * getter: outgoingDocumentTypes
   */
  itemFactory({
    name: 'outgoingDocumentTypes',
    primaryKey: 'key',
    fetchMethod: () =>
      medrecApi.getOutgoingDocumentTypes({ limit: 10000, page: 1 }),
  }),

  /**
   * Get list of account roles
   *
   * action: fetchAccountRoles
   * getter: accountRoles
   */
  itemFactory({
    name: 'accountRoles',
    fetchMethod: () => authApi.fetchRolesPreviews({ kind: 'administrative' }),
  }),

  /**
   * Get list of account roles
   *
   * action: fetchAccountRolesApplication
   * getter: accountRolesApplication
   */
  itemFactory({
    name: 'accountRolesApplication',
    fetchMethod: () => authApi.fetchRolesPreviews({ kind: 'application' }),
  }),

  /**
   * Get role types list
   *
   * action: fetchRoleTypes
   * getter: roleTypes
   */
  itemFactory({
    name: 'roleTypes',
    fetchMethod: () => authApi.fetchRoleTypes(),
  }),

  /**
   * Get scenario flags
   *
   * action: fetchScenarioFlags
   * getter: scenarioFlags
   */
  itemFactory({
    name: 'scenarioFlags',
    fetchMethod: () =>
      scenariosApi.getFlags({ limit: 10000, page: 1 }).then(data => data.items),
  }),

  /**
   * Get scenario types
   *
   * action: fetchScenarioTypes
   * getter: scenarioTypes
   */
  itemFactory({
    name: 'scenarioTypes',
    fetchMethod: () => scenariosApi.getTypes(),
  }),

  /**
   * Get processing types
   *
   * action: fetchInspectionTypes
   * getter: inspectionTypes
   */
  itemFactory({
    name: 'inspectionTypes',
    fetchMethod: () => processingApi.getTypes(),
  }),

  /**
   * Get extra fields for employee (integrations-service)
   *
   * action: fetchEmployeeExtrasTypes
   * getter: employeeExtrasTypes
   */
  itemFactory({
    name: 'employeeExtrasTypes',
    fetchMethod: () =>
      integrationsApi.getEmployeeExtrasTypes({ limit: 10000, page: 1 }),
  }),

  /**
   * Get inspection remarks
   *
   * action: fetchInspectionRemarks
   * getter: inspectionRemarks
   */
  itemFactory({
    name: 'inspectionRemarks',
    fetchMethod: () => processingApi.getRemarks(),
  }),

  {
    getters: {
      inspectionRemarkTypes: state =>
        (state.inspectionRemarks || []).reduce((agg, el) => {
          agg[el.id] = el.type;
          return agg;
        }, {}),
    },
  },

  /**
   * Get local vars for scheduler jobs (scheduler-service)
   *
   * action: fetchSchedulerLocals
   * getter: schedulerLocals
   */
  itemFactory({
    defaultValue: {},
    name: 'schedulerLocals',
    fetchMethod: () => schedulerApi.getLocals({ limit: 10000, page: 1 }),
  }),

  /**
   * Get medical tests types for employee (medrec-service)
   *
   * action: fetchMedicalTestTypes
   * getter: medicalTestTypes
   */
  itemFactory({
    name: 'medicalTestTypes',
    fetchMethod: () => medrecApi.getMedicalTestTypes({ limit: 10000, page: 1 }),
  }),

  /**
   * Get templates (+ translations) for formFactors, inspTypes & stepTypes
   *
   * action: fetchProfileTemplates
   * getter: profileTemplates
   */
  itemFactory({
    defaultValue: {
      formFactor: [],
      inspectionType: [],
      stepType: [],
    },
    name: 'profileTemplates',
    fetchMethod: () => gateApi.getTemplates(),
  }),
);
