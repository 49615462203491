<template>
  <div>
    <v-alert
      v-if="!userIsMedic"
      style="width: 300px; margin: 0 auto"
      class="my-3"
      dense
      outlined
      type="error"
    >
      Вы не медработник
    </v-alert>
    <v-alert
      v-else-if="!$can('T_CP')"
      style="width: 400px; margin: 0 auto"
      class="my-3"
      dense
      outlined
      type="error"
    >
      Нет доступа к тестовой подписи. Обратитесь в тех. поддержку
    </v-alert>

    <div
      v-else-if="!isCheckedCertificate"
      class="d-flex justify-center align-center mt-4"
    >
      <v-card width="500" class="text-center px-3 pb-5">
        <v-card-title>Для начала работы выберите ЭЦП</v-card-title>
        <CheckCertificate :account-id="accountId" @submit="submitCertificate" />
      </v-card>
    </div>

    <MedcabLayout v-else>
      <v-layout
        v-if="
          isFetchingShift ||
          isOutsideShift ||
          shiftError ||
          isPaused ||
          isAwaitingServer ||
          timeIsOver
        "
        fill-height
        justify-center
        align-center
        column
      >
        <Spinner
          v-if="isFetchingShift"
          title="Загрузка данных о смене"
          size="80"
          line-width="7"
        />
        <WarningBlock
          v-else-if="isOutsideShift && !shiftError"
          :title="{ title: 'Готовы начать смену?', content: 'Вы вне смены.' }"
        />
        <WarningBlock
          v-else-if="isOutsideShift && shiftError"
          :title="{
            title: 'Ваша смена завершена',
            content: `Это можно произойти по двум причинам:
              – вы закрыли её в другом окне;
              – её закрыл сервер потому, что прошло более 27 часов с начала смены;`,
          }"
        />
        <WarningBlock v-else-if="isPaused" :title="stopReason" :error="error" />
        <Spinner
          v-else-if="isAwaitingServer"
          title="Ожидание подходящего осмотра"
          size="80"
          line-width="7"
        />
        <Spinner
          v-else-if="timeIsOver && !isPaused"
          title="Ожидание ответа от сервера"
          size="80"
          line-width="7"
        />
      </v-layout>
      <v-layout v-else column>
        <InspectionHeader />
        <v-btn
          v-if="isAwaitingAcceptance"
          data-sentry-mark="medcab:active:takeInspection"
          class="align-self-center mt-4"
          width="50%"
          height="108px"
          x-large
          color="primary"
          :loading="$wait('taking')"
          :disabled="disableButtons || timeIsOver"
          @click="handlerTakeInspection"
        >
          Взять в работу
        </v-btn>
        <InspectionBody v-else-if="isInspecting" />
      </v-layout>

      <!-- Actions in the bottom of page-->
      <template v-if="actions.fail" #actions.left>
        <v-btn
          v-if="isInspecting && !timeIsOver"
          data-sentry-mark="medcab:active:openResolve"
          color="error"
          height="100%"
          width="100%"
          outlined
          elevation="2"
          :disabled="disableButtons || timeIsOver"
          @click="openResolveModal"
        >
          {{ actions.fail }}
        </v-btn>
      </template>
      <template v-if="isOutsideShift" #actions.center>
        <v-btn
          key="openShift"
          data-sentry-mark="medcab:active:openShift"
          class="ml-4"
          height="100%"
          color="primary"
          :disabled="disableButtons"
          @click="handlerOpenShift"
        >
          Начать смену
        </v-btn>
      </template>
      <template v-else-if="timeIsOver || isPaused" #actions.center>
        <v-btn
          key="resumeShift"
          data-sentry-mark="medcab:active:resumeShift"
          class="ml-4"
          height="100%"
          color="primary"
          :disabled="disableButtons || !isPaused"
          @click="handlerResumeShift"
        >
          Возобновить<br />работу
        </v-btn>
        <ScoreBlock
          class="ml-4"
          label="Осмотров за смену"
          :score="closedInspections"
        />
        <v-btn
          key="closeShift"
          data-sentry-mark="medcab:active:stopShift"
          class="ml-4"
          height="100%"
          color="warning"
          outlined
          :disabled="disableButtons || !isPaused"
          @click="stopShiftModal"
        >
          Окончить<br />смену
        </v-btn>
      </template>
      <template v-else-if="isOnlineSSE" #actions.center>
        <ScoreBlock
          v-if="(isAwaitingAcceptance || isInspecting) && !timeIsOver"
          label="Таймер"
          :score="timer"
        />
        <ScoreBlock
          v-if="isAwaitingServer || isAwaitingAcceptance"
          class="ml-4"
          label="Осмотров за смену"
          :score="closedInspections"
        />
        <StatisticOnline class="ml-4" />
        <v-btn
          v-if="!isInspecting"
          key="stop"
          data-sentry-mark="medcab:active:pause"
          class="ml-4"
          height="100%"
          color="primary"
          outlined
          :disabled="disableButtons || timeIsOver"
          @click="handlerPause"
        >
          Пауза
        </v-btn>
        <FakeInspections v-if="isMedcabDebug" />
      </template>
      <template v-if="actions.pass" #actions.right>
        <v-btn
          v-if="isInspecting && !timeIsOver"
          data-sentry-mark="medcab:active:permitInspection"
          color="success"
          height="100%"
          width="100%"
          outlined
          elevation="2"
          :disabled="!canMedicPermit || disableButtons"
          @click="permitInspection"
        >
          {{ actions.pass }}
        </v-btn>
      </template>
      <template v-else-if="actions.validate" #actions.right>
        <v-btn
          v-if="isInspecting && !timeIsOver"
          data-sentry-mark="medcab:active:openValidate"
          color="primary"
          height="100%"
          width="100%"
          outlined
          elevation="2"
          :disabled="disableButtons"
          @click="openValidateModal"
        >
          {{ actions.validate }}
        </v-btn>
      </template>
    </MedcabLayout>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import waitable from '@/utils/mixins/waitable';
import env from '@/plugins/env';
import sound from '../soundMixin';

// components go below
import Spinner from '@/components/Spinner.vue';
import MedcabLayout from '../components/MedcabLayout.vue';
import WarningBlock from '../components/WarningBlock.vue';
import InspectionHeader from '../components/InspectionHeader.vue';
import InspectionBody from '../components/InspectionBody.vue';
import ScoreBlock from '../components/ScoreBlock.vue';
import StatisticOnline from '../components/StatisticOnline.vue';
import CheckCertificate from '../components/CheckCertificate.vue';
import FakeInspections from '../components/FakeInspections.vue';
import authApi from '@/api/services/auth';
import signApi from '@/api/services/sign';

export default {
  components: {
    MedcabLayout,
    WarningBlock,
    Spinner,
    InspectionHeader,
    InspectionBody,
    ScoreBlock,
    StatisticOnline,
    CheckCertificate,
    FakeInspections,
  },

  mixins: [waitable, sound('active')],

  beforeRouteLeave(from, to, next) {
    if (this.isOnlineSSE) {
      this.$notify({
        group: 'note',
        type: 'error',
        title: 'Сначала остановите работу',
      });
    } else next();
  },

  data: () => ({
    isCheckedCertificate: false,
  }),

  computed: {
    ...mapGetters('MEDCAB', [
      'isFetchingShift',
      'isOutsideShift',
      'isPaused',
      'isOnlineSSE',
      'isAwaitingServer',
      'isAwaitingAcceptance',
      'isInspecting',
      'timer',
      'closedInspections',
      'shiftError',
      'timeIsOver',

      'stopReason',
      'error',
    ]),
    ...mapGetters('MEDCAB_INSPECTION', [
      'type',
      'assistance',
      'assistantDisabled',
      'videoMatch',
    ]),
    ...mapGetters('AUTH', ['accountId', 'currentAccount', 'disabled2fa']),

    isMedcabDebug() {
      return env.get('VUE_APP_MEDCAB_DEBUG');
    },

    userIsMedic() {
      return this.currentAccount.type === 'medic';
    },

    actions() {
      return this.type?.details?.actions || {};
    },

    canMedicPermit() {
      if (this.assistantDisabled) return true;
      return this.assistance.result?.level !== 'obligatory';
    },

    tokenIsActive() {
      return !!this.$store.getters['TOKENS/activeToken'];
    },

    disableButtons() {
      return (
        this.$wait('resumeShift') ||
        this.$wait('taking') ||
        this.$wait('resolution') ||
        this.$wait('pause') ||
        this.$wait('openShift')
      );
    },
  },

  watch: {
    tokenIsActive: {
      immediate: true,
      handler(val) {
        this.isCheckedCertificate = val;
      },
    },

    isCheckedCertificate: {
      immediate: true,
      handler(val) {
        if (val) this.$store.dispatch('MEDCAB/runSession');
      },
    },
  },

  async created() {
    this.$store.dispatch('REGISTRY/fetchInspectionRemarks');
    if (this.tokenIsActive) this.isCheckedCertificate = true;
    if (this.userIsMedic) this.$store.dispatch('AUTH/fetchMedicDetails');
  },

  activated() {
    this.startPing();
  },
  deactivated() {
    this.stopPing();
  },
  beforeDestroy() {
    this.stopPing();
  },

  methods: {
    ...mapActions('TIME', ['startPing', 'stopPing']),
    ...mapActions('MEDCAB', [
      'openShift',
      'closeShift',
      'resumeShift',
      'pause',
      'take',
    ]),
    ...mapActions('AUTH', ['getDisabled2fa']),

    handlerResumeShift() {
      this.$loading(this.resumeShift(), 'resumeShift');
    },

    handlerPause() {
      this.$loading(this.pause(), 'pause');
    },

    handlerTakeInspection() {
      this.$loading(this.take(), 'taking');
    },
    handlerOpenShift() {
      const openShift = async () => {
        await this.getDisabled2fa();

        // Тестовая подпись
        if (!this.disabled2fa) {
          const data = await authApi.getCryptoParams({
            thumbprint:
              this.$store.getters['TOKENS/activeToken'].data.thumbprint,
          });
          await signApi.sendTestTaskComplete(data);
        }

        this.openShift();
      };
      this.$loadingNotify(
        openShift(),
        'openShift',
        'Ошибка при подписании тестового документа',
      );
    },

    stopShiftModal() {
      this.$openModal('medcab/closeShift', {
        closedInspections: this.closedInspections,
        closeShift: this.closeShift,
      });
    },

    permitInspection() {
      const actionName = this.actions.pass || this.actions.validate;
      const props = {
        title: actionName + ' работника?',
        assistantDisabled: this.assistantDisabled,
        medcabType: 'active',
        onSubmit: ({ comment }) =>
          this.$loading(
            this.$store.dispatch('MEDCAB/permit', {
              comment: (comment || '').trim() || null,
              assistantDisabled: this.assistantDisabled,
            }),
            'resolution',
          ),
        yes: 'Подтвердить',
      };

      // Сообщение о подлоге на видео
      if (
        this.videoMatch?.status === 'done' &&
        this.videoMatch?.result?.color === 'RED'
      ) {
        props.warningText =
          'Похоже, что на видео другой человек, вы уверены в своем действии?';
        props.warningType = 'error';
      }

      const modalId = this.$openModal('medcab/prompt', props);

      const unwatch = this.$watch('isInspecting', () => {
        this.$closeModal(modalId);
        unwatch();
      });
    },

    openResolveModal() {
      const modalId = this.$openModal('medcab/resolve', {
        medcabType: 'active',
        onSubmit: (comment, remarks) =>
          this.$loading(
            this.$store.dispatch('MEDCAB/resolve', {
              comment,
              remarks,
              assistantDisabled: this.assistantDisabled,
            }),
            'resolution',
          ),
      });

      const unwatch = this.$watch('isInspecting', () => {
        this.$closeModal(modalId);
        unwatch();
      });
    },

    openValidateModal() {
      const validateModalId = this.$openModal('medcab/resolve', {
        medcabType: 'active',
        onSubmit: (comment, remarks) =>
          this.$loading(
            this.$store.dispatch('MEDCAB/validate', {
              comment,
              remarks,
              assistantDisabled: this.assistantDisabled,
            }),
            'resolution',
          ),
      });

      const unwatch = this.$watch('isInspecting', () => {
        this.$closeModal(validateModalId);
        unwatch();
      });
    },

    async submitCertificate({ token, debug = false }) {
      // only for testing
      if (env.get('VUE_APP_MEDCAB_DEBUG') && debug) {
        this.isCheckedCertificate = true;
        return;
      }

      await this.$store.dispatch('TOKENS/changeActiveToken', {
        type: token.type,
        token,
      });
      this.isCheckedCertificate = true;
    },
  },
};
</script>
