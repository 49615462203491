<template>
  <ItemPageLayout back-route="structures:hosts" :title="singleItem.name">
    <template #header>
      <Card dense hide-title>
        <v-tabs v-model="currentTab">
          <v-tab href="#general">Основное</v-tab>
          <v-tab href="#logs">Лог изменений</v-tab>
        </v-tabs>
      </Card>
    </template>

    <div v-if="currentTab === 'general'">
      <Card title="Данные ПАК" dense class="mb-3">
        <DefinitionList :value="computedEntity" />
        <template #action>
          <v-btn
            v-if="$can('HST_U')"
            depressed
            small
            text
            color="primary"
            @click="editModal"
          >
            Редактировать
          </v-btn>
        </template>
      </Card>

      <Card v-if="$can('GT_HS')" title="Настройки ПАК" dense>
        <AsyncWrapper ref="hostSettings" :handler="prepareSettings">
          <div>
            <v-alert class="ma-4" dense outlined type="info">
              {{
                isCustomSettings
                  ? 'Применяются настройки ПАК'
                  : 'Применяются настройки организации'
              }}
            </v-alert>
            <v-card v-if="isCustomSettings">
              <DevicesSettings
                v-if="
                  conf.devicesSettings && conf.devicesSettings.thermometerMode
                "
                v-model="conf.devicesSettings"
                :disabled="!settingsEditing"
              />
              <InspectionTypes
                ref="InspectionTypes"
                :scenarios="conf.scenarios"
                :disable-actions="!settingsEditing"
                :is-create="false"
                :inspection-types="inspectionTypes"
                :step-types="stepTypes"
                :hide-sign="true"
                :full-steps-list="false"
              />
            </v-card>
          </div>
        </AsyncWrapper>

        <template #action>
          <div class="d-flex" style="gap: 10px">
            <CardActionBtn
              v-if="settingsEditing"
              label="Сохранить"
              :disabled="$wait('updateConfigProfile')"
              @click="saveSettingsChanges"
            />
            <v-btn
              v-show="settingsEditing"
              small
              depressed
              icon
              class="ml-1"
              @click="closeSettingsEditing"
            >
              <v-icon size="16">fa-times</v-icon>
            </v-btn>
            <CardActionBtn
              v-if="$can('ADD_HS') && !settingsEditing && singleItem.formFactor"
              label="Изменить"
              :disabled="$wait('fetchAndSetOrgSettings')"
              @click="openSettingsEditing"
            />
            <CardActionBtn
              v-if="$can('C_HS') && !settingsEditing && isCustomSettings"
              :disabled="$wait('fetchAndSetOrgSettings')"
              label="Удалить"
              @click="deleteSettings"
            />
          </div>
        </template>
      </Card>
    </div>

    <template v-else-if="currentTab === 'logs'">
      <Card class="mb-4" dense>
        <ChangesList :fetch-function="fetchEventReport" />
      </Card>
    </template>

    <template v-if="currentTab === 'general'" #aside>
      <ActionsMenu>
        <ActionsMenuItem
          v-if="$can('HSG_SB')"
          title="Изменить группы"
          @click="openEditHostGroups"
        >
          <template #icon><v-icon>mdi-pencil-outline</v-icon></template>
        </ActionsMenuItem>
        <NewStatusChanger
          instance="ПАК"
          :item="singleItem"
          :can-enable="$can('HST_EN')"
          :can-disable="$can('HST_DS')"
          :handler="changeStatusHandler"
        />
        <ActionsMenuItem
          v-if="$can('NTR_HST')"
          :title="niiatTitle"
          :subtitle="niiatSubtitle"
          :disabled="niiatRegistrationDisabled"
          @click="niiatRegistrationHandle"
        >
          <template v-if="$wait('niiatRegistration')" #icon>
            <v-progress-circular indeterminate :size="20" :width="2" />
          </template>
        </ActionsMenuItem>
        <ActionsMenuDelete
          v-if="$can('HST_D')"
          :id="singleItem.id"
          store-module="STRUCTURES/HOSTS"
          back-route="structures:hosts"
          title="Удалить ПАК"
          description="Вы уверены, что хотите удалить ПАК?"
        />
        <ActionsMenuItem
          v-if="parentOrgProfile"
          class="text-decoration-none"
          title="Профиль организации"
          :to="routeToProfile"
          :disabled="!$can('GT_GPR')"
        >
          <template #subtitle>
            <span class="text-uppercase primary--text">
              {{ parentOrgProfile.name }}
            </span>
          </template>
        </ActionsMenuItem>
      </ActionsMenu>
    </template>
  </ItemPageLayout>
</template>

<script>
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';
import waitable from '@/utils/mixins/waitable';

import ItemPageLayout from '@/components/layouts/ItemPageLayout';
import DefinitionList from '@/components/DefinitionList';
import ActionsMenuDelete from '@/components/ActionsMenuDelete';
import Card from '@/components/ui/Card';
import ActionsMenuItem from '@/components/ui/ActionsMenuItem';
import ActionsMenu from '@/components/ui/ActionsMenu';
import LinkButton from '@/components/controls/buttons/LinkButton';
import LinkButtonList from '@/components/controls/buttons/LinkButtonList';
import NewStatusChanger from '@/components/controls/NewStatusChanger';
import HostGroupSelect from '@/components/controls/structures/HostGroupSelect.vue';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';
import AsyncWrapper from '@/components/AsyncWrapper.vue';
import InspectionTypes from '@/components/InspectionTypes';
import DevicesSettings from '@/components/DevicesSettings';
import ChangesList from '@/components/ChangesList';

import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import blameApi from '@/api/services/blame';
import Vue from 'vue';
import { DateTime } from '@/plugins/luxon';

export default {
  components: {
    ItemPageLayout,
    DefinitionList,
    Card,
    ChangesList,
    ActionsMenu,
    ActionsMenuDelete,
    ActionsMenuItem,
    NewStatusChanger,
    CardActionBtn,
    AsyncWrapper,
    InspectionTypes,
    DevicesSettings,
  },

  mixins: [waitable, routeGuardMixin],

  data() {
    return {
      settingsEditing: false,
      conf: {},
      currentTab: 'general',
    };
  },
  computed: {
    ...mapGetters('STRUCTURES/HOSTS_ITEM', [
      'formFactors',
      'configProfile',
      'parentOrgProfile',
    ]),
    ...mapGetters('REGISTRY', ['profileTemplates']),

    routeToProfile() {
      return {
        name: 'admin:profiles:item',
        params: { key: this.parentOrgProfile.key },
      };
    },

    niiatTitle() {
      return this.singleItem.niiatStatus.registered
        ? 'ПАК зарегистрирован в АИС ЕРП'
        : 'Внести запись о ПАК в АИС ЕРП';
    },
    niiatSubtitle() {
      return this.singleItem.niiatStatus.registered
        ? ''
        : 'Действие нельзя отменить';
    },
    niiatRegistrationDisabled() {
      return (
        this.singleItem.niiatStatus.registered ||
        this.$wait('niiatRegistration')
      );
    },

    inspectionTypes() {
      const it = this.profileTemplates.inspectionType;
      return Object.keys(it)
        .filter(k => Object.keys(this.configProfile.scenarios).includes(k))
        .map(k => ({ name: it[k], key: k }));
    },

    stepTypes() {
      return Object.keys(this.profileTemplates.stepType);
    },

    computedEntity() {
      if (!this.singleItem) return [];

      const v = this.singleItem;

      const table = [
        { label: 'ID', value: v.id },
        { label: 'Статус', value: v.isActive ? 'Активен' : 'Неактивен' },
        {
          label: 'Вид конфигурации',
          value: v.isCustomProfile ? 'Настраеваемый' : 'По умолчанию',
        },
        { label: 'Название', value: v.name },
        { label: 'Лицензия', value: v.license },
        {
          label: 'Форм-фактор',
          // formFactor must always come from backend
          value: this.formFactors[v.formFactor],
        },
        {
          label: 'Вариант исполнения',
          value: v.medicalDeviceType?.shortName,
        },
        {
          label: 'Номер свидетельства поверки',
          value: v.verificationCertificate?.number,
        },
        {
          label: 'Дата поверки',
          value: v.verificationCertificate?.date
            ? DateTime.fromISO(v.verificationCertificate.date).toFormat(
                'dd.MM.y',
              )
            : null,
        },
        {
          label: 'Дата окончания поверки',
          value: v.verificationCertificate?.date
            ? DateTime.fromISO(v.verificationCertificate.date)
                .plus({ years: 1 })
                .minus({ days: 1 })
                .toFormat('dd.MM.y')
            : null,
        },
        { label: 'Описание', value: v.description },
        {
          label: 'Организация',
          component: LinkButton,
          props: {
            text: v.organization.name || `#${v.organization.id}`,
            action: this.$can('ORG_RD'),
            to: {
              name: 'structures:organizations:item',
              params: { id: v.organization.id },
            },
          },
        },
        {
          label: 'Точка выпуска',
          component: LinkButton,
          props: {
            text: v.releasePoint.address || `#${v.releasePoint.id}`,
            action: this.$can('PNT_RD'),
            to: {
              name: 'structures:points:item',
              params: { id: v.releasePoint.id },
            },
          },
        },
        v.groups.length && {
          label: 'Группы ПАК',
          component: LinkButtonList,
          props: {
            list: v.groups.map(el => ({
              text: el.name || `#${el.id}`,
              action: this.$can('HSG_RD'),
              to: {
                name: 'structures:host_groups:item',
                params: { id: el.id },
              },
            })),
          },
        },
        { label: 'Тип', value: v.isOpenpoint ? 'Открытая' : 'Закрытая' },
        {
          label: 'Запрет на автоматическую печать',
          value: v.isNotPrint ? 'Да' : 'Нет',
        },
        {
          label: 'Запрет на повторную печать стикера',
          value: v.isNotReprint ? 'Да' : 'Нет',
        },
        { label: 'Причина запрета печати', value: this.item.notPrintComment },
      ];

      return table;
    },

    isCustomSettings() {
      return Object.keys(this.configProfile?.scenarios || {}).length !== 0;
    },
  },

  watch: {
    configProfile() {
      Vue.set(this, 'conf', cloneDeep(this.configProfile));
      // контент не появится после ошибки,
      // поскольку asyncwrapper оперирует собственным хандлером
      // поэтому при изменении данных очищаем ошибку вручную
      this.$refs.hostSettings.error = null;
    },
    currentTab() {
      this.settingsEditing = false;
    },
  },

  methods: {
    ...mapActions('STRUCTURES/HOSTS_ITEM', [
      'fetchConfigProfile',
      'updateConfigProfile',
      'deleteConfigProfile',
      'fetchAndSetOrgSettings',
      'niiatRegistration',
      'changeStatus',
    ]),
    ...mapActions('REGISTRY', ['fetchProfileTemplates']),
    ...mapActions('STRUCTURES/HOST_GROUPS', {
      changeCountOnHostGroups: 'changeCounter',
    }),
    ...mapActions('STRUCTURES/POINTS', {
      changeCountOnPoints: 'changeCounter',
    }),

    async prepareSettings() {
      if (!this.singleItem.formFactor) {
        throw Error('Для получения настроек ПАК укажите форм-фактор');
      }
      await this.$loadingNotify(
        this.fetchProfileTemplates(),
        'fetchingProfileTemplates',
        'Произошла ошибка загрузки шаблонов профилей',
      );
      await this.$loadingNotify(
        this.fetchConfigProfile(),
        'fetchConfigProfile',
        'Произошла ошибка загрузки настроек ПАК',
      );
    },

    async changeStatusHandler(newStatus) {
      await this.changeStatus(newStatus);
      let addCount = 1;
      if (!newStatus) addCount = -1;

      if (this.singleItem.groups.length)
        this.changeCountOnHostGroups({
          itemIds: this.singleItem.groups.map(group => group.id),
          counterField: 'itemsCount',
          addCount,
        });
      if (this.singleItem.releasePoint)
        this.changeCountOnPoints({
          itemIds: [this.singleItem.releasePoint.id],
          counterField: 'hostsCount',
          addCount,
        });
    },

    editModal() {
      this.$openModal('structures/hostForm', {
        value: this.singleItem,
      });
      this.settingsEditing = false;
    },

    openEditHostGroups() {
      this.$openModal('asyncSelectModal', {
        component: HostGroupSelect,
        value: this.singleItem.groups,
        multiple: true,
        componentProps: {
          accessLevel: 'full',
          orgIds: [this.singleItem.organization?.id],
          omitGuests: true,
        },
        onSubmit: async groups => {
          const oldGroupsIds = this.singleItem.groups.map(group => group.id);
          await this.$loadingNotify(
            this.$store.dispatch('STRUCTURES/HOSTS_ITEM/updateGroups', groups),
            'updateGroups',
            'Произошла ошибка обновления групп',
            'Группы успешно обновлены',
          );
          this.changeCountOnHostGroups({
            itemIds: oldGroupsIds,
            counterField: 'itemsCount',
            addCount: -1,
          });
          this.changeCountOnHostGroups({
            itemIds: groups,
            counterField: 'itemsCount',
            addCount: 1,
          });
        },
        messages: {
          title: 'Редактирование групп ПАК',
          warning: `Группы ПАК для организации ${this.singleItem.organization.name} отсутствуют`,
        },
      });
    },

    checkEmptiness(payload) {
      const t = Object.values(payload); // just a shorting

      const someScenarioEnabled = t.some(scenario => scenario.isEnabled);
      const noEmptyScenarios = t
        .filter(scenario => scenario.isEnabled)
        .every(scenario =>
          Object.values(scenario.steps).some(step => step.status),
        );
      return someScenarioEnabled && noEmptyScenarios;
    },

    async openSettingsEditing() {
      if (!this.isCustomSettings) {
        await this.$loadingNotify(
          this.fetchAndSetOrgSettings(),
          'fetchAndSetOrgSettings',
          'Не удалось загрузить настройки ПАК',
        );
      }
      this.settingsEditing = true;
    },

    async closeSettingsEditing() {
      await this.$loadingNotify(
        this.fetchConfigProfile(),
        'fetchConfigProfile',
        'Произошла ошибка загрузки настроек ПАК',
      );

      if (this.$refs.InspectionTypes) this.$refs.InspectionTypes.prepare();

      this.settingsEditing = false;
    },

    async saveSettingsChanges() {
      const saveErrMsg = 'Произошла ошибка при сохранении настроек ПАК';
      const scenarios = this.$refs.InspectionTypes.cleanPayload();

      if (!this.checkEmptiness(scenarios)) {
        this.$notify({
          group: 'note',
          type: 'error',
          title: saveErrMsg,
          text: 'Хотя бы 1 тип осмотра должен быть включен, а во включенном типе должен быть включен хотя бы 1 этап. Проверьте настройки профиля.',
        });
        return;
      }

      await this.$loadingNotify(
        this.updateConfigProfile({ ...this.conf, scenarios }),
        'updateConfigProfile',
        saveErrMsg,
        'Настройки ПАК сохранены',
      );
      this.settingsEditing = false;
    },

    deleteSettings() {
      this.$openModal('prompt', {
        title: 'Удалить настройки ПАК',
        description:
          'Кастомные настройки ПАК сбросятся и станут дефолтными. Вы уверены?',
        yes: 'Да',
        no: 'Нет',
        messages: {
          successfulAction: 'Настройки успешно удалены',
          errorAction: 'При удалении настроек произошла ошибка',
        },
        onSubmit: this.deleteConfigProfile,
      });
    },

    fetchEventReport({ page, limit }) {
      const id = this.singleItem.id;
      return blameApi.getEntityReport({
        page,
        limit,
        entity: 'HOST',
        id,
      });
    },

    async niiatRegistrationHandle() {
      await this.$loadingNotify(
        this.niiatRegistration(),
        'niiatRegistration',
        'Ошибка внесения записи о ПАК в АИС ЕРП. Повторите попытку',
      );
    },
  },
};
</script>
