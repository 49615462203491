<template>
  <ModernModalLayout
    title="Управление ЭЦП"
    close-action-name="закрыть"
    :watch-for="data"
  >
    <v-row class="pb-4">
      <v-col :cols="12">
        <h3 class="font-weight-bold">Подключенные</h3>
      </v-col>
      <v-col v-if="certs.length" class="pt-0" :cols="12">
        <v-tooltip v-for="cert in certs" :key="cert.id" top>
          <template #activator="{ on, attrs }">
            <v-chip
              class="my-1 mr-1"
              v-bind="attrs"
              close
              v-on="on"
              @click:close="unbindCertificate(cert.type, cert.id)"
            >
              <span class="mr-2">
                <v-icon
                  :color="
                    cert.isInitialized && cert.isActive
                      ? 'green darkest-2'
                      : cert.isInitialized || cert.isActive
                      ? 'orange'
                      : 'red darkest-1'
                  "
                >
                  {{
                    cert.isInitialized && cert.isActive
                      ? 'mdi-check-bold'
                      : 'mdi-alert'
                  }}
                </v-icon>
              </span>
              {{ cert.label }}
            </v-chip>
          </template>
          <span>
            {{ cert.value }} ({{
              cert.isInitialized ? 'инициализирован' : 'не инициализирован'
            }}, {{ cert.isActive ? 'активен' : 'отключен' }})
          </span>
        </v-tooltip>
      </v-col>

      <v-col v-if="!certs.length" class="text--disabled" :cols="12">
        Нет подключенных ЭЦП
      </v-col>
    </v-row>
    <v-divider class="my-1" />
    <div>
      <v-row>
        <v-col :cols="12">
          <h3 class="my-4 font-weight-bold">Добавление сертификата</h3>
          <v-select
            v-model="data.type"
            placeholder="Выберите тип сертификата"
            :items="AUTH_METHODS"
            item-value="value"
            item-text="name"
            menu-props="auto"
            hide-details
            outlined
            dense
            :disabled="$wait('removeCertificate')"
          />
        </v-col>

        <v-col v-if="data.type === TOKEN_TYPES['CRYPTOPRO']" :cols="12">
          <MaskField
            key="data.cryptopro.serial"
            v-model="data.cryptopro.serial"
            :mask="maskInputCryptoPro"
            placeholder="XXXX XXXX XXXX XXXX XXXX XXXX XXXX XXXX XX"
            label="Серийный номер КриптоПро"
            outlined
            dense
            hide-details="auto"
            first-format
            :error-messages="getValidationErrors('data.cryptopro.serial')"
            @blur="handleFieldBlur('data.cryptopro.serial')"
          />
        </v-col>
        <v-col v-if="data.type === TOKEN_TYPES['CRYPTOPROTEST']" :cols="12">
          <MaskField
            key="data.cryptoprotest.serial"
            v-model="data.cryptoprotest.serial"
            :mask="maskInputCryptoProTest"
            placeholder="XXXX XXXX XXXX XXXX XXXX XXXX XXXX XXXX XXXX XX"
            label="Серийный номер КриптоПро"
            outlined
            dense
            hide-details="auto"
            first-format
            :error-messages="getValidationErrors('data.cryptoprotest.serial')"
            @blur="handleFieldBlur('data.cryptoprotest.serial')"
          />
        </v-col>
        <template v-if="data.type === TOKEN_TYPES['SIGNME']">
          <v-col :cols="12">
            <MaskField
              key="data.signme.serial"
              v-model="data.signme.serial"
              :mask="maskInputSignMe"
              placeholder="XXXXX"
              label="Серийный номер SignMe"
              outlined
              dense
              hide-details="auto"
              first-format
              :error-messages="getValidationErrors('data.signme.serial')"
              @blur="handleFieldBlur('data.signme.serial')"
            />
          </v-col>
          <v-col :cols="12">
            <PhoneField
              key="data.signme.phone"
              v-model="data.signme.phone"
              outlined
              dense
              hide-details="auto"
              :error-messages="getValidationErrors('data.signme.phone')"
              @blur="handleFieldBlur('data.signme.phone')"
            />
          </v-col>
        </template>
      </v-row>

      <v-alert v-if="error" class="mt-4" text type="error">{{ error }}</v-alert>
    </div>

    <template #actions:append>
      <v-btn
        class="px-10"
        depressed
        color="primary"
        :disabled="
          !data.type || $wait('addCertificate') || $wait('removeCertificate')
        "
        @click="handleSubmit"
      >
        Добавить
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import required from 'vuelidate/lib/validators/required';
import validationMixin from '@/utils/validation';
import { phone } from '@/utils/validators';
import { xhrErrorMessage } from '@/utils/helpers';
import {
  maskTextCryptoPro,
  maskInputCryptoPro,
  maskInputCryptoProTest,
  maskInputSignMe,
  unmaskPhone,
} from '@/utils/masks';
import { TOKEN_TYPES } from '@/utils/constants';
import waitable from '@/utils/mixins/waitable';
import ModernModalLayout from '@/components/layouts/ModernModalLayout';
import PhoneField from '@/components/controls/PhoneField';
import MaskField from '@/components/controls/MaskField';
import env from '@/plugins/env';

export default {
  components: {
    ModernModalLayout,
    PhoneField,
    MaskField,
  },
  mixins: [validationMixin, waitable],

  props: {
    medicId: {
      required: true,
      type: Number,
    },
    items: {
      required: true,
      type: Array,
    },
    accountId: {
      type: Number,
      default: null,
    },
  },

  validations() {
    const rules = this.AUTH_METHODS.find(
      ({ value }) => value === this.data.type,
    );
    return {
      data: {
        [this.data.type]: rules.validationRules || {},
      },
    };
  },

  data: () => ({
    data: {
      type: null,
      cryptopro: {
        serial: null,
      },
      cryptoprotest: {
        serial: null,
      },
      signme: {
        serial: null,
        phone: null,
      },
    },
    error: null,
  }),

  computed: {
    maskInputCryptoPro: () => maskInputCryptoPro,
    maskInputCryptoProTest: () => maskInputCryptoProTest,
    maskInputSignMe: () => maskInputSignMe,

    TOKEN_TYPES() {
      return TOKEN_TYPES;
    },

    AUTH_METHODS() {
      const authMethods = [
        {
          name: 'КриптоПро',
          value: TOKEN_TYPES['CRYPTOPRO'],
          mask: maskTextCryptoPro,
          validationRules: {
            serial: { required },
          },
        },
      ];
      this.$can('TST_CRT') &&
        authMethods.push({
          name: 'КриптоПро (Тестовый)',
          value: TOKEN_TYPES['CRYPTOPROTEST'],
          mask: maskTextCryptoPro,
          validationRules: {
            serial: { required },
          },
        });
      !env.get('VUE_APP_SIGNME_DISABLE') &&
        authMethods.push({
          name: 'Sign.Me',
          value: TOKEN_TYPES['SIGNME'],
          validationRules: {
            serial: { required },
            phone: { required, phone },
          },
        });
      return authMethods;
    },
    authMethodsByValue() {
      return this.AUTH_METHODS.reduce((agg, el) => {
        agg[el.value] = el.name;
        return agg;
      }, {});
    },

    certs() {
      return this.items.map(el => {
        const method = this.AUTH_METHODS.find(am => am.value === el.type);

        return {
          id: el.id,
          label: method?.name || el.type,
          isActive: el.isActive,
          isInitialized: el.isInitialized,
          type: el.type,
          value: method?.mask
            ? method.mask(el.details.serial)
            : el.details.serial,
        };
      });
    },
  },

  methods: {
    async bindCertificate() {
      if (!this.accountId)
        throw Error(`Для пользователя ${this.medicId} не создан аккаунт`);

      const fields = {};
      switch (this.data.type) {
        case TOKEN_TYPES['SIGNME']:
          fields.serial = this.data.signme.serial;
          fields.phone = unmaskPhone(this.data.signme.phone || '');
          break;
        case TOKEN_TYPES['CRYPTOPRO']:
          fields.serial = (this.data.cryptopro.serial || '')
            .replace(/\s/g, '')
            .toUpperCase();
          break;
        case TOKEN_TYPES['CRYPTOPROTEST']:
          fields.serial = (this.data.cryptoprotest.serial || '')
            .replace(/\s/g, '')
            .toUpperCase();
          break;
      }

      const action = this.$store.dispatch('MEDBLOCK/MEDICS/addCertificate', {
        medicId: this.medicId,
        type: this.data.type,
        accountId: this.accountId,
        fields,
      });

      return this.$loadingNotify(
        action,
        'addCertificate',
        `Произошла ошибка добавления ЭЦП`,
        `ЭЦП добавлен`,
      )
        .then(() => {
          this.$emit('close');
          return action;
        })
        .catch(() => {});
    },

    async handleSubmit() {
      this.error = null;
      if (!this.validate()) return;

      try {
        await this.bindCertificate();
      } catch (err) {
        console.error(err);
        this.error = xhrErrorMessage(err);
      }
    },

    async unbindCertificate(type, id) {
      try {
        await this.$loadingNotify(
          this.$store.dispatch('MEDBLOCK/MEDICS/removeCertificate', {
            medicId: this.medicId,
            certificateId: id,
          }),
          'removeCertificate',
          `Произошла ошибка удаления ЭЦП`,
          `ЭЦП удалена`,
        );
      } catch (err) {
        console.error(err);
        this.error = xhrErrorMessage(err);
      }
    },
  },
};
</script>
