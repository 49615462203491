import Vue from 'vue';
import VueRouter from 'vue-router';
import qs from 'query-string';

import { can } from '@/plugins/can';
import store from '@/store';
import { NAME_ROOT_ROUTE } from './utils/constants';
import env from './plugins/env';

Vue.use(VueRouter);

const ScrollPositionHistory = new Map();

/**
 * Получить первый доступный пользователю роут
 */
const getFirstAvailableRoute = () => {
  const menuItems = store.getters['menu']
    .map(item => item.children || [item])
    .flat(1);

  const firstAvailableRoute = menuItems.find(item => can(item.action));
  return firstAvailableRoute;
};

const routes = [
  {
    path: '/',
    name: NAME_ROOT_ROUTE,
    component: () => import('@/views/Application.vue'),
    meta: {
      // Доступ на любую страницу приложения (даже 404) только после авторизации
      requiresAuth: true,
    },
    redirect: () => {
      const router = getFirstAvailableRoute();
      const isAuthorized = store.getters['AUTH/isAuthorized'];
      return { name: router?.name || (isAuthorized ? 'forbidden' : 'login') };
    },
    children: [], // Заполняется динамически в модулях
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Provider.vue'),
    meta: {
      // Открывается только неавторизованным пользователям
      onlyGuest: true,
    },
    redirect: { name: 'login:email' },
    children: [
      {
        path: '',
        name: 'login:email',
        component: () => import('@/views/Login.vue'),
      },
      {
        path: 'token',
        name: 'login:token',
        component: () => import('@/views/LoginByToken.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: env.get('VUE_APP_BASE_URL'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.matched.some(m => m.meta.disableScrollRestoring)) return;

    if (to.params.restoreScrollPosition && ScrollPositionHistory.has(to.name))
      return ScrollPositionHistory.get(to.name);

    return savedPosition || { x: 0, y: 0 };
  },

  parseQuery(query) {
    const result = qs.parse(query, {
      arrayFormat: 'separator',
      arrayFormatSeparator: '|',
    });
    return result;
  },

  stringifyQuery(params) {
    const result = qs.stringify(params, {
      arrayFormat: 'separator',
      arrayFormatSeparator: '|',
      skipEmptyString: true,
    });
    return result ? '?' + result : '';
  },
});

/*
 * Auth middleware
 * Check if user is authorized and show only available page for their
 */
router.beforeEach((to, from, next) => {
  const isAuthorized = store.getters['AUTH/isAuthorized'];
  const isMedic = store.getters['AUTH/isMedic'];
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Страницы доступные только после авторизации

    if (!isAuthorized) {
      // Если пользователь не авторизован переводим на страницу авторизации
      next({ name: 'login', query: { redirect: to.fullPath } });
      return;
    }

    if (isAuthorized) {
      // Если пользователь авторизован, то проверяем разрешения
      const { action } = to.meta;
      const neededPermissions = !!action;
      const isHavePermissions = can(action);

      // Если нет разрешений на открытие страницы - показываем 403
      if (neededPermissions && !isHavePermissions) {
        next({ name: 'forbidden' });
        return;
      }
    }
  } else if (to.matched.some(record => record.meta.onlyGuest)) {
    // Страницы доступные только для не авторизированных пользователей.
    // Защита от зацикливания редиректа и определение страниц авторизации
    if (isAuthorized) {
      // Если пользователь пытается открыть страницы авторизации, то
      // отправляем в приложение
      next({ name: 'main' });
      return;
    } else if (to.name === 'login:token' && !isMedic) {
      // Только медикам, у которых инициализирована эцп, доступна авторизация по криптопро
      next({ name: 'login:email' });
      return;
    }
  }

  next();
});

/*
 * Scroll middleware
 * Save scroll position to return to previous position when moving in history
 */
router.beforeEach(async (to, from, next) => {
  const x = document.documentElement.scrollLeft;
  const y = document.documentElement.scrollTop;

  if (from.name) ScrollPositionHistory.set(from.name, { x, y });
  return next();
});

/*
 * Global router error handler
 */
router.onError(err => {
  router
    .push({ name: 'errorPage', props: { error: err } })
    .catch(err => console.error(err));
});

/**
 * Добавить в список роутов страницы с ошибками.
 * Необходимо в связи с динамическим формированием списка роутов в реализации
 * модульного подхода.
 *
 * !!! Вызывать когда роуты модулей сгенерировались
 */
function activateGuardRoutes() {
  // Важен порядок!
  // Первый элемент будет отображен пользователю при отсутствии страницы
  const guardRoutes = [
    {
      path: '/error',
      name: 'errorPage',
      component: () => import('@/views/ErrorPage.vue'),
    },
    {
      path: '/forbidden',
      name: 'forbidden',
      component: () => import('@/views/ErrorPage.vue'),
      props: { message: 'Вам недоступна эта страница' },
    },
    {
      path: '*',
      name: 'pageNotFound',
      component: () => import('@/views/ErrorPage.vue'),
      props: { message: 'Страница не существует' },
    },
  ];

  guardRoutes.forEach(route => router.addRoute(NAME_ROOT_ROUTE, route));
}

export default router;

export { router, activateGuardRoutes };
