<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <div class="d-flex justify-space-between align-center">
        <span>{{ inspType.name }}</span>
        <CardActionBtn
          :disabled="disableActions"
          :label="inspTypeEnableLabel"
          @click.stop="$emit('toggleType', inspType)"
        />
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div v-if="!scenario.isEnabled" class="py-3 pb-0">
        Для настройки включите этот тип осмотра.
      </div>
      <div v-else>
        <v-simple-table v-if="!hideSign" class="insptype_table">
          <colgroup>
            <col width="41px" />
            <col width="auto" />
            <col width="80px" />
            <col width="80px" />
          </colgroup>
          <thead>
            <tr class="mt-3">
              <th class="px-0 text-decoration-underline" colspan="2"></th>
              <th class="px-0 pr-5 text-center text-decoration-underline">
                Статус
              </th>
              <th class="px-0 mx-0 text-center">
                <div class="d-flex align-center justify-center mx-0 px-0">
                  <div class="text-decoration-underline">Прервать подпись</div>
                  <v-tooltip max-width="300" top>
                    <template #activator="{ on }">
                      <v-icon class="ml-1" size="15" v-on="on">
                        fa-question-circle
                      </v-icon>
                    </template>
                    <span>
                      Возможность прервать подпись действует как для подписи с
                      пин-кодом, так и для упрощенной подписи (чек-бокс согласия
                      с результатами)
                    </span>
                  </v-tooltip>
                </div>
              </th>
            </tr>
          </thead>
          <tr>
            <td class="pa-0">
              <v-tooltip max-width="300" top>
                <template #activator="{ on }">
                  <v-icon size="15" class="ml-3" v-on="on">
                    fa-question-circle
                  </v-icon>
                </template>
                <span>
                  Если данный тип подписи отключен, то на ПАК будет действовать
                  упрощенная подпись (чек-бокс согласия с результатами)
                </span>
              </v-tooltip>
            </td>
            <td class="py-3 px-0">Подпись осмотра (пин-код)</td>
            <td class="py-3 px-0">
              <v-switch
                v-model="scenario.signingRequired"
                style="margin: auto !important; width: fit-content"
                inset
                class="ma-0 pa-0"
                hide-details
                :disabled="disableActions"
              />
            </td>
            <td class="py-3 px-0">
              <v-switch
                v-model="scenario.signingCancellable"
                style="margin: auto !important; width: fit-content"
                inset
                hide-details
                class="ma-0 pa-0"
                :disabled="disableActions"
              />
            </td>
          </tr>
        </v-simple-table>
        <v-divider class="my-2 mt-0" />
        <v-simple-table>
          <colgroup>
            <col width="41px" />
            <col width="auto" />
            <col width="80px" />
            <col width="80px" />
          </colgroup>
          <thead>
            <tr class="mt-3 text-decoration-underline">
              <th class="px-0" colspan="2">Этапы осмотра</th>
              <th class="px-0 pr-5 text-center">Статус</th>
              <th class="px-0 text-center">Прервать осмотр</th>
            </tr>
          </thead>
          <draggable
            v-model="scenario.stepOrder"
            :group="inspType.key"
            handle=".insp-type-panel-handle"
            tag="tbody"
            :animation="300"
            :disabled="disableActions"
          >
            <tr v-for="(step, y) in scenario.stepOrder" :key="y">
              <td class="pa-0">
                <v-icon size="14" class="insp-type-panel-handle mx-3">
                  fa-arrows-alt
                </v-icon>
              </td>
              <template v-if="scenario.steps[step].items">
                <td colspan="3" class="pa-0">
                  <v-simple-table class="transparent">
                    <colgroup>
                      <col width="auto" />
                      <col width="80px" />
                      <col width="80px" />
                    </colgroup>
                    <tbody>
                      <tr
                        v-for="(item, i) in scenario.steps[step].items"
                        :key="i"
                      >
                        <td class="py-3 px-0">
                          {{ profileTemplates.stepType[item.type] }}
                        </td>
                        <td class="py-3 px-0">
                          <v-switch
                            v-model="item.status"
                            style="margin: auto !important; width: fit-content"
                            inset
                            class="ma-0 pa-0"
                            hide-details
                            :disabled="disableActions"
                            @change="resetStep(item)"
                          />
                        </td>
                        <td class="py-3 px-0">
                          <v-switch
                            v-model="item.cancellable"
                            style="margin: auto !important; width: fit-content"
                            inset
                            hide-details
                            class="ma-0 pa-0"
                            :disabled="!item.status || disableActions"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </template>
              <template v-else>
                <td class="py-3 px-0">
                  {{ profileTemplates.stepType[step] }}
                </td>
                <td class="py-3 px-0">
                  <v-switch
                    v-model="scenario.steps[step].status"
                    style="margin: auto !important; width: fit-content"
                    inset
                    class="ma-0 pa-0"
                    hide-details
                    :disabled="disableActions"
                    @change="resetStep(scenario.steps[step])"
                  />
                </td>
                <td class="py-3 px-0">
                  <v-switch
                    v-model="scenario.steps[step].cancellable"
                    style="margin: auto !important; width: fit-content"
                    inset
                    hide-details
                    class="ma-0 pa-0"
                    :disabled="!scenario.steps[step].status || disableActions"
                  />
                </td>
              </template>
            </tr>
          </draggable>
        </v-simple-table>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex';

import CardActionBtn from '@/components/controls/buttons/CardActionBtn';
import draggable from 'vuedraggable';

export default {
  components: { CardActionBtn, draggable },
  props: {
    inspType: { type: Object, required: true },
    value: { type: Object, required: true },
    disableActions: { type: Boolean, required: true },
    isCreate: { type: Boolean, required: true },
    hideSign: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('REGISTRY', ['profileTemplates']),
    scenario: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },

    inspTypeEnableLabel() {
      if (!this.disableActions) {
        return !this.scenario.isEnabled ? 'Включить' : 'Выключить';
      } else {
        return !this.scenario.isEnabled ? 'Выключен' : 'Включен';
      }
    },
  },

  methods: {
    resetStep(item) {
      // if user disabled step - reset 'cancellable' field as well
      if (!item.status) item.cancellable = false;
    },
  },
};
</script>

<style lang="scss">
.insptype_table table {
  overflow: hidden !important;
}
</style>
