import notifApi from '@/api/services/notifications';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';

export default createModule(
  crudListFactory({
    fetchMethod: notifApi.host_verificationsList,
    createMethod: notifApi.host_verificationsCreate,
  }),
);
