import { changePages } from '@/utils/vuex/changePages';

export const LIST_STATUSES = ['not_loaded', 'loading', 'loaded', 'error'];

const notImplemented = () => {
  throw new Error('Action is not implemented');
};

export default function crudListFactory({
  fetchMethod = notImplemented,
  createMethod = notImplemented,
  updateMethod = notImplemented,
  deleteMethod = notImplemented,
  exportMethod = notImplemented,
  filters = {},
  defaultSort = {
    orderBy: 'id',
    orderType: false,
  },
} = {}) {
  /** Side effect for change filters */
  const changeQueryFilters = (state, newValue, needResetPage = false) => {
    if (needResetPage) state.needResetPage = true;
    state.listQuery = { ...state.listQuery, ...newValue };
  };

  /** List key filters => [key, ...] */
  const filterKeys = Object.keys(filters || {});

  /** List key filters with type "array" => [key, ...] */
  const filterArrayKeys = filterKeys.reduce(
    (agg, key) => (filters[key].type === Array ? [...agg, key] : agg),
    [],
  );

  /** Object value filters => {key: value, ...} */
  const filtersValues = filterKeys.reduce((agg, key) => {
    agg[key] = filters[key].default;
    return agg;
  }, {});

  const filtersGetters = Object.fromEntries(
    filterKeys.map(name => [name, state => state.listQuery[name]]),
  );

  const filtersMutations = Object.fromEntries(
    filterKeys.map(name => [
      name,
      (state, value) => changeQueryFilters(state, { [name]: value }, true),
    ]),
  );

  return {
    state: {
      // items
      listItems: [],
      listStatus: LIST_STATUSES[0],
      listError: null,
      listTotal: 0,

      // query
      listQuery: {
        ...filtersValues,
        page: 1,
        limit: 50,
        orderBy: defaultSort.orderBy,
        orderType: defaultSort.orderType,
        search: '',
      },

      // export
      listExportLoading: false,
      listExportError: null,

      // Need for reset filter pages after change filter
      needResetPage: false,
    },
    getters: {
      // items
      listItems: state => state.listItems,
      listTotal: state => state.listTotal,
      listIsLoading: state =>
        state.listStatus === LIST_STATUSES[0] ||
        state.listStatus === LIST_STATUSES[1],
      listItem: state => id => state.listItems.find(item => item.id === id),
      listError: state => state.listError,

      // query
      listLimit: state => state.listQuery.limit,
      listSearch: state => state.listQuery.search,
      listOrderBy: state => state.listQuery.orderBy,
      listOrderType: state => state.listQuery.orderType,
      listOrder: state => ({
        orderType: state.listQuery.orderType ? 'DESC' : 'ASC',
        orderBy: state.listQuery.orderBy,
      }),
      listCurrentPage: state => state.listQuery.page,
      listFilters: state =>
        Object.fromEntries(
          Object.entries(state.listQuery)
            .map(([key, value]) => {
              if (
                value &&
                Array.isArray(value) &&
                filterArrayKeys.includes(key)
              )
                return [key, value.length ? value.join(',') : null];
              return [key, value];
            })
            .filter(([_, value]) => value != null),
        ),
      listQuery: (state, getters) => ({
        ...getters.listFilters,
        ...getters.listOrder,
        search: state.listQuery.search || null,
      }),
      listExportFilters: state =>
        Object.fromEntries(
          Object.entries(state.listQuery).filter(([_, value]) => value != null),
        ),
      listExportQuery: (state, getters) => {
        const dates = {
          dateStart:
            getters.listExportFilters?.dateStart ||
            getters.datePlaceholder?.dateStart ||
            null,
          dateEnd:
            getters.listExportFilters?.dateEnd ||
            getters.datePlaceholder?.dateEnd ||
            null,
        };

        return {
          ...getters.listExportFilters,
          ...getters.listOrder,
          ...dates,
          search: state.listQuery.search || undefined,
          limit: undefined,
          page: undefined,
        };
      },
      ...filtersGetters,

      // page count
      listTotalPages: state =>
        !state.listTotal
          ? 0
          : Math.ceil(state.listTotal / state.listQuery.limit),
      listShowPagination: state => state.listTotal / state.listQuery.limit > 1,

      // export
      listExportLoading: state => state.listExportLoading,

      //
      needResetPage: state => state.needResetPage,
    },
    mutations: {
      // items
      listFetching: state => {
        state.listItems = [];
        state.listTotal = null;
        state.listStatus = LIST_STATUSES[1];
        state.listError = null;
      },
      listFetched: (state, { items, total }) => {
        state.listItems = items || [];
        state.listTotal = total || 0;
        state.listStatus = LIST_STATUSES[2];
        state.listError = null;
      },
      listErrorFetched: (state, error) => {
        state.listItems = [];
        state.listTotal = null;
        state.listStatus = LIST_STATUSES[3];
        state.listError = error;
      },
      listAddItem: (state, value) => {
        state.listItems = [value, ...state.listItems];
        state.listTotal += 1;
      },
      listUpdateItem: (state, value) => {
        const primaryField = value.id ? 'id' : 'key';
        const index = state.listItems.findIndex(
          item => item[primaryField] === value[primaryField],
        );
        if (index === -1) return;

        state.listItems = [
          ...state.listItems.slice(0, index),
          { ...state.listItems[index], ...value },
          ...state.listItems.slice(index + 1),
        ];
      },
      listRemoveItem: (state, id) => {
        const index = state.listItems.findIndex(
          item => (item.id || item.key) === id,
        );
        if (index === -1) return;

        state.listItems = [
          ...state.listItems.slice(0, index),
          ...state.listItems.slice(index + 1),
        ];
        state.listTotal -= 1;
      },

      // query
      needResetPage: (state, value) => (state.needResetPage = value),
      listQuery: (state, value) => changeQueryFilters(state, { ...value }),
      listOrderBy: (state, value) =>
        changeQueryFilters(state, { orderBy: value }),
      listOrderType: (state, value) =>
        changeQueryFilters(state, { orderType: value }),
      listSearch: (state, value) =>
        changeQueryFilters(state, { search: value }, true),
      listLimit: (state, value) =>
        changeQueryFilters(state, { limit: value }, true),
      listCurrentPage: (state, value) =>
        changeQueryFilters(state, { page: value }),
      listResetPage: state => changeQueryFilters(state, {}, true),
      listResetFilters: state => {
        state.needResetPage = false;
        state.listQuery = {
          ...filtersValues,
          page: 1,
          limit: 50,
          orderBy: defaultSort.orderBy,
          orderType: defaultSort.orderType,
          search: '',
        };
      },
      ...filtersMutations,

      listExporting: state => {
        state.listExportLoading = true;
        state.listExportError = null;
      },
      listExported: state => {
        state.listExportLoading = false;
        state.listExportError = null;
      },
      listErrorExported: (state, error) => {
        state.listExportLoading = false;
        state.listExportError = error;
      },

      changeCounter: (state, { itemIds, counterField, addCount }) => {
        const items = state.listItems.filter(item => itemIds.includes(item.id));
        if (items.length) {
          items.forEach(item => {
            if (Number.isInteger(item[counterField])) {
              item[counterField] += addCount;
            }
          });
        }
      },
    },
    actions: {
      async fetchList({ commit, getters }, query) {
        await changePages(getters, commit, query);

        try {
          if (getters.listSearch?.length > 100)
            throw Error('Не более 100 символов в поле поиска');

          commit('listFetching');
          const response = await fetchMethod(getters.listQuery);
          commit('listFetched', response);
        } catch (error) {
          commit('listErrorFetched', error);
          throw error;
        }
      },
      querySearchList({ commit, getters }, search) {
        // 'replace' below works only if user typed some phone format in
        // search field. To prevent any errors we delete '+' from phone number
        search = search?.replace(/^\+/g, ' ').trim() || '';

        if (search === getters.listSearch) return;
        commit('listSearch', search);
        commit('listResetPage');
      },
      resetListFilters: ({ commit }) => {
        commit('listResetFilters');
      },

      async createListItem({ commit }, data) {
        const response = await createMethod(data);
        commit('listAddItem', response);
        return response;
      },
      async updateListItem({ commit }, data) {
        const response = await updateMethod(data);
        commit('listUpdateItem', response);
        return response;
      },
      async deleteListItem({ commit }, id) {
        await deleteMethod(id);
        commit('listRemoveItem', id);
      },

      async exportList({ commit, getters }) {
        try {
          commit('listExporting');
          const query = Object.entries(getters.listExportQuery).filter(
            ([_, value]) => (Array.isArray(value) ? value.length : true),
          );
          const response = await exportMethod(Object.fromEntries(query));
          commit('listExported', response);
          return response;
        } catch (error) {
          commit('listErrorExported', error);
          throw error;
        }
      },

      changeCounter({ commit }, { itemIds, counterField, addCount = 0 }) {
        if (!itemIds || !itemIds.length || !counterField) return;
        commit('changeCounter', { itemIds, counterField, addCount });
      },
    },
  };
}
