<template>
  <ItemPageLayout
    back-route="structures:points"
    :title="singleItem.address || 'Точка #' + singleItem.id"
  >
    <Card title="Данные точки" dense>
      <DefinitionList :value="computedEntity" />
      <template #action>
        <v-btn
          v-if="$can('PNT_U')"
          depressed
          small
          text
          color="primary"
          @click="editModal"
        >
          Редактировать
        </v-btn>
      </template>
    </Card>

    <Card v-if="$can('PNT_GA')" title="Параметры окружающей среды" dense>
      <AsyncWrapper :handler="fetchAmbientSettings">
        <v-simple-table dense>
          <tbody>
            <tr v-for="item in ambientFields" :key="item.key">
              <td class="grey--text px-4 py-3" style="width: 40%">
                {{ item.name }}
              </td>
              <td class="px-4 py-3" style="width: 60%">
                <span v-if="!editing">
                  <template v-if="ambient[item.key]">
                    {{ ambient[item.key] }} {{ ambientUnit[item.key] }}
                  </template>
                  <template v-else>—</template>
                </span>
                <MaskField
                  v-else
                  v-model="ambientInner[item.key]"
                  :mask="
                    item.key === 'temperature' ? maskOneDigit : maskTwoDigits
                  "
                  style="max-width: 150px"
                  :placeholder="item.placeholder"
                  class="ma-0 pa-0"
                  :disabled="$wait('updatingAmbientSettings')"
                  outlined
                  dense
                  hide-details="auto"
                  :error-messages="
                    getValidationErrors('ambientInner.' + item.key)
                  "
                  @blur="validateField('ambientInner.' + item.key)"
                />
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </AsyncWrapper>
      <template #action>
        <div>
          <CardActionBtn
            v-if="$can('PNT_UA') && !editing"
            label="Изменить"
            :loading="$wait('updatingAmbientSettings')"
            :disabled="loading"
            @click="toggleEditing"
          />
          <template v-else-if="$can('PNT_UA') && editing">
            <v-btn
              v-if="true"
              color="success"
              outlined
              small
              class="mr-3"
              @click="handleAmbientSettingsUpdate"
            >
              <v-icon size="16" class="mr-2">far fa-save</v-icon>
              <span>Сохранить</span>
            </v-btn>
            <v-btn outlined small class="mr-1" @click="toggleEditing">
              <v-icon size="16">fa-times</v-icon>
            </v-btn>
          </template>
        </div>
      </template>
    </Card>

    <template #aside>
      <ActionsMenu>
        <NewStatusChanger
          instance="точку выпуска"
          :item="singleItem"
          store-module="STRUCTURES/POINTS_ITEM"
          :can-enable="$can('PNT_EN')"
          :can-disable="$can('PNT_DS')"
        />
        <ActionsMenuDelete
          v-if="$can('PNT_D')"
          :id="singleItem.id"
          store-module="STRUCTURES/POINTS"
          back-route="structures:points"
          title="Удалить группу работников"
          description="Вы уверены, что хотите удалить группу?"
        />
      </ActionsMenu>
    </template>

    <template v-if="$can('HST_R')" #innerList>
      <List
        search-filter
        :available-actions="{
          showAction: $can('HST_RD'),
          createAction: $can('HST_C'),
        }"
        @item:create="openCreate"
      >
        <template #filters="query">
          <v-col cols="12" sm="4" md="3">
            <v-select
              v-model="query.isActive"
              label="Статус"
              :items="statusItems"
              item-value="value"
              item-text="name"
              hide-details
              outlined
              dense
              :menu-props="{ offsetY: true }"
            />
          </v-col>
        </template>

        <template #table.item.nameLicense="{ item }">
          <b>{{ item.name }}</b>
          <br />
          {{ item.license }}
        </template>

        <template #table.item.org="{ item }">
          {{ item.organization.shortName || item.organization.name }}<br />
        </template>

        <template #table.item.isActive="{ item }">
          <Indicator
            :active="item.isActive"
            :tooltip="item.isActive ? 'Активен' : 'Неактивен'"
          />
        </template>

        <template #table.item.isOpenpoint="{ item }">
          <v-tooltip left>
            <template #activator="{ on }">
              <v-icon
                :color="item.isOpenpoint ? 'green lighten-2' : 'red'"
                v-on="on"
              >
                mdi-lock{{ item.isOpenpoint ? '-open' : '' }}-outline
              </v-icon>
            </template>
            {{ item.isOpenpoint ? 'Открытая' : 'Закрытая' }}
          </v-tooltip>
        </template>

        <template #table.item.isNotPrint="{ item }">
          <Indicator
            :active="item.isNotPrint"
            active-color="red"
            passive-color="grey lighten-2"
            :tooltip="item.isNotPrint ? item.notPrintComment : 'Автоматическая'"
          />
        </template>

        <template #table.item.isNotReprint="{ item }">
          <Indicator
            :active="item.isNotReprint"
            active-color="red"
            passive-color="grey lighten-2"
            :tooltip="item.isNotReprint ? item.notPrintComment : 'Разрешено'"
          />
        </template>
      </List>
    </template>
  </ItemPageLayout>
</template>

<script>
import routeGuardMixin from '@/utils/mixins/routeGuardMixin';

import { STATUS } from '@/utils/constants.js';
import { entity } from '../entity';
import { mapActions, mapGetters } from 'vuex';
import { decimal, minValue } from 'vuelidate/lib/validators';
import validationMixin from '@/utils/validation';
import waitable from '@/utils/mixins/waitable';
import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';

// components
import ItemPageLayout from '@/components/layouts/ItemPageLayout';
import MaskField from '@/components/controls/MaskField';
import NewStatusChanger from '@/components/controls/NewStatusChanger';
import Card from '@/components/ui/Card';
import ActionsMenu from '@/components/ui/ActionsMenu';
import LinkButton from '@/components/controls/buttons/LinkButton';
import DefinitionList from '@/components/DefinitionList';
import ActionsMenuDelete from '@/components/ActionsMenuDelete';
import Indicator from '@/components/Indicator.vue';
import AsyncWrapper from '@/components/AsyncWrapper';
import CardActionBtn from '@/components/controls/buttons/CardActionBtn';

// List section code
import ListFactory from '@/components/crud/ListFactory';
import {
  messages,
  entityEmbedded as embedEntity,
  filters,
} from '@/modules/structures/hosts/entity';
const List = ListFactory({
  storeModule: 'STRUCTURES/POINTS_ITEM_HOSTS_LIST',
  messages,
  entity: embedEntity.filter(val => !['org_releasePoint'].includes(val.key)),
  filters,
  viewRoute: 'structures:hosts:item',
  embedded: true,
});

export default {
  components: {
    ItemPageLayout,
    DefinitionList,
    Card,
    ActionsMenu,
    ActionsMenuDelete,
    NewStatusChanger,
    AsyncWrapper,
    CardActionBtn,
    MaskField,

    List,
    Indicator,
  },
  mixins: [routeGuardMixin, waitable, validationMixin],

  data: () => ({
    editing: false,
    loading: false,
    ambientInner: {},
    ambientUnit: {
      humidity: '%',
      lux: 'Лк',
      temperature: '°C',
    },
  }),

  validations() {
    return {
      ambientInner: {
        lux: {
          decimal,
          minValue: minValue(0.01),
        },
        humidity: {
          decimal,
          minValue: minValue(0.01),
        },
        temperature: {
          decimal,
          minValue: minValue(0.01),
        },
      },
    };
  },

  computed: {
    ...mapGetters('STRUCTURES/POINTS_ITEM', ['ambient']),
    computedEntity() {
      if (!this.singleItem) return [];

      const table = entity
        .filter(el => el.key !== 'organization')
        .map(({ key, label, convert }) => ({
          label,
          value: convert ? convert(this.singleItem) : this.singleItem[key],
        }));

      const org = this.singleItem?.organization;
      org &&
        table.push({
          label: 'Организация',
          component: LinkButton,
          props: {
            text: org.name || `#${org.id}`,
            action: this.$can('ORG_RD'),
            to: {
              name: 'structures:organizations:item',
              params: { id: org.id },
            },
          },
        });

      return table;
    },
    statusItems: () => STATUS,
    maskTwoDigits: () => ({ mask: /^(?=.*\d)\d*(?:\.\d{0,2})?$/ }),
    maskOneDigit: () => ({ mask: /^(?=.*\d)\d*(?:\.\d?)?$/ }),
    ambientFields() {
      return [
        { key: 'humidity', name: 'Влажность', placeholder: '00.0%' },
        { key: 'lux', name: 'Освещенность', placeholder: '00.0Лк' },
        { key: 'temperature', name: 'Температура', placeholder: '00.0℃' },
      ];
    },
  },

  beforeMount() {
    // we need to reset filters on each 'item page' mount, cut those are being
    // saved in store
    this.resetListFilters();
  },

  methods: {
    ...mapActions('STRUCTURES/POINTS_ITEM', [
      'updateAmbientSettings',
      'fetchAmbientSettings',
    ]),
    ...mapActions('STRUCTURES/POINTS_ITEM_HOSTS_LIST', ['resetListFilters']),

    editModal() {
      this.$openModal('structures/pointsForm', {
        item: this.singleItem,
      });
    },
    openCreate() {
      const id = this.singleItem.id;
      this.$openModal('structures/hostForm', {
        predefinedValues: {
          orgId: this.singleItem.organization.id,
          releasePointId: this.singleItem.id,
        },
        storeForCreating: 'STRUCTURES/POINTS_ITEM_HOSTS_LIST',
        onSubmit() {
          this.$store.dispatch(
            'STRUCTURES/POINTS_ITEM/fetchSingle',
            this.predefinedValues.releasePointId,
          );
          this.$store.dispatch('STRUCTURES/POINTS_ITEM/singleItemUpdate', id);
        },
      });
    },

    toggleEditing() {
      if (!this.editing) {
        Vue.set(this, 'ambientInner', cloneDeep(this.ambient));
        this.editing = true;
      } else {
        this.ambientInner = {};
        this.editing = false;
      }
    },

    handleAmbientSettingsUpdate() {
      if (!this.validate()) return;

      this.$openModal('prompt', {
        yes: 'Да',
        no: 'Отмена',
        title: 'Подтверждение',
        description:
          'Вы действительно хотите изменить параметры окружающей среды?',
        messages: {
          successfulAction: 'Параметры окружающей среды успешно изменены',
          errorAction: 'Возникла ошибка изменения параметров окружающей среды',
        },
        onSubmit: () =>
          this.$loading(
            this.updateAmbientSettings({ ...this.ambientInner }).then(() => {
              this.toggleEditing();
            }),
            'updatingAmbientSettings',
          ),
      });
    },
  },
};
</script>
