import notifApi from '@/api/services/notifications';
import crudListFactory from '@/utils/vuex/crudListFactory';
import { createModule } from '@/utils/vuex/createModule';

const state = {
  templateLocals: null,
};

const getters = {
  templateLocals: state => state.templateLocals || [],
};

const mutations = {
  setTemplateLocals(state, data) {
    state.templateLocals = data;
  },
};

const actions = {
  async fetchTemplateLocals({ state, commit }) {
    if (state.templateLocals) return;
    const list = await notifApi.getTemplateLocals();
    commit('setTemplateLocals', list);
  },

  async createListItem({ commit }, data) {
    const response = await notifApi.template_setsCreate(data);
    commit('listAddItem', response);
  },
};

export default createModule(
  crudListFactory({
    fetchMethod: notifApi.template_setsList,
    defaultSort: {
      orderBy: 'id',
      orderType: true,
    },
  }),
  { state, getters, mutations, actions },
);
