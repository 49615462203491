import { MOSCOW_TIMEZONE } from '../constants';
import { ReportScheme, ReportSchemes } from '../models';
import { required, requiredIf } from 'vuelidate/lib/validators';

export const baseScheme: ReportScheme = {
  fields: [
    {
      preset: 'Organization',
      validation: { required },
      watch: context =>
        context.$emit('input', {
          ...context.value,
          pointIds: context.value.includeAllPoints ? null : [],
        }),
    },
    {
      preset: 'EmployeeGroups',
      props: value => ({
        disabled: !value.orgId,
        orgIds: value.orgId ? [value.orgId] : null,
      }),
    },
    {
      is: context => context.noBatch,
      preset: 'Points',
      props: value => ({
        disabled: !value.orgId || value.includeActivePoints,
        orgIds: value.orgId ? [value.orgId] : null,
      }),
      validation: {
        required: requiredIf(values => !values.includeActivePoints),
      },
    },
    {
      preset: 'IncludeActivePoints',
      is: context => context.noBatch,
      watch(context) {
        // Сбросить выбранную точку
        context.value.pointId = undefined;
        return context;
      },
    },
    {
      is: context => !context.noBatch,
      preset: 'Points',
      cols: 5,
      props: value => ({
        disabled: !value.orgId || value.includeAllPoints,
        orgIds: value.orgId ? [value.orgId] : null,
      }),
      validation: {
        required: requiredIf(
          values => !values.includeAllPoints && !values.compositeFile,
        ),
      },
    },
    {
      preset: 'IncludeAllPoints',
      cols: 3,
      is: context => !context.noBatch,
      watch(context) {
        // Сбросить выбранные точки
        context.value.pointIds = context.value.includeAllPoints
          ? undefined
          : [];
        return context;
      },
    },
    {
      preset: 'CompositeFile',
      cols: 4,
      is: context => !context.noBatch,
    },
    {
      preset: 'DateRange',
      validation: { required },
      cols: 12,
    },
    {
      preset: 'IncludeInspectedCheckbox',
    },
  ],
  fixedValues: {
    timezone: MOSCOW_TIMEZONE,
    timeDisplayMode: 'system',
  },
  reportType: values => (values.compositeFile ? 'file' : 'composite'),
};

export const mgtScheme: ReportScheme = {
  fields: [
    {
      preset: 'Organization',
      validation: { required },
      watch: context =>
        context.$emit('input', {
          ...context.value,
          pointIds: context.value.includeAllPoints ? null : [],
          pointId: null,
        }),
    },
    {
      preset: 'EmployeeGroups',
      props: value => ({
        disabled: !value.orgId,
        orgIds: value.orgId ? [value.orgId] : null,
      }),
    },
    {
      is: context => context.noBatch,
      preset: 'Point',
      props: value => ({
        disabled: !value.orgId || value.includeActivePoints,
        orgIds: value.orgId ? [value.orgId] : null,
      }),
      validation: {
        required: requiredIf(values => !values.includeActivePoints),
      },
    },
    {
      preset: 'IncludeActivePoints',
      is: context => context.noBatch,
      watch(context) {
        // Сбросить выбранную точку
        context.value.pointId = undefined;
        return context;
      },
    },
    {
      is: context => !context.noBatch,
      preset: 'Points',
      props: value => ({
        disabled: !value.orgId || value.includeAllPoints,
        orgIds: value.orgId ? [value.orgId] : null,
      }),
      validation: {
        required: requiredIf(values => !values.includeAllPoints),
      },
    },
    {
      preset: 'IncludeAllPoints',
      is: context => !context.noBatch,
      watch(context) {
        // Сбросить выбранные точки
        context.value.pointIds = context.value.includeAllPoints
          ? undefined
          : [];
        return context;
      },
    },
    {
      preset: 'DateRange',
      validation: { required },
      cols: 12,
    },
    {
      preset: 'IncludeInspectedCheckbox',
    },
    {
      preset: 'IncludeCancelledCheckbox',
      validation: { required },
    },
  ],
  fixedValues: {
    timezone: MOSCOW_TIMEZONE,
    timeDisplayMode: 'system',
  },
  batchByField: 'pointIds',
  batchedFieldName: 'pointId',
  reportType: values =>
    values.includeAllPoints || values.includeActivePoints
      ? 'composite'
      : 'file',
};

export const edoScheme: ReportScheme = {
  fields: [
    {
      preset: 'Organization',
      watch: context =>
        context.$emit('input', {
          ...context.value,
          pointId: undefined,
        }),
      validation: { required },
    },
    {
      preset: 'Point',
      props: value => ({
        disabled: !value.orgId,
        orgIds: value.orgId ? [value.orgId] : null,
      }),
      validation: { required },
    },
    {
      preset: 'DateRange',
      validation: { required },
      cols: 12,
    },
  ],
  reportType: () => 'composite',
};

export const mtaScheme: ReportScheme = {
  fields: [
    {
      preset: 'Organization',
      validation: { required },
      watch: context =>
        context.$emit('input', {
          ...context.value,
          pointIds: context.value.includeAllPoints ? null : [],
          pointId: null,
        }),
      cols: 12,
    },
    {
      is: context => context.noBatch,
      preset: 'Point',
      props: value => ({
        disabled: !value.orgId || value.includeActivePoints,
        orgIds: value.orgId ? [value.orgId] : null,
      }),
      validation: {
        required: requiredIf(values => !values.includeActivePoints),
      },
    },
    {
      preset: 'IncludeActivePoints',
      is: context => context.noBatch,
      watch(context) {
        // Сбросить выбранную точку
        context.value.pointId = undefined;
        return context;
      },
    },
    {
      is: context => !context.noBatch,
      preset: 'Points',
      props: value => ({
        disabled: !value.orgId || value.includeAllPoints,
        orgIds: value.orgId ? [value.orgId] : null,
      }),
      validation: {
        required: requiredIf(values => !values.includeAllPoints),
      },
    },
    {
      preset: 'IncludeAllPoints',
      is: context => !context.noBatch,
      watch(context) {
        // Сбросить выбранные точки
        context.value.pointIds = context.value.includeAllPoints
          ? undefined
          : [];
        return context;
      },
    },
    {
      preset: 'DateRange',
      validation: { required },
      cols: 12,
    },
    {
      preset: 'IncludeInspectedCheckbox',
    },
    {
      preset: 'IncludeCancelledCheckbox',
      validation: { required },
    },
  ],
  fixedValues: {
    timezone: MOSCOW_TIMEZONE,
    timeDisplayMode: 'system',
  },
  batchByField: 'pointIds',
  batchedFieldName: 'pointId',
  reportType: values =>
    values.includeAllPoints || values.includeActivePoints
      ? 'composite'
      : 'file',
};

export const schemes: ReportSchemes = {
  'med-journal': baseScheme,
  'mgt-med-journal': mgtScheme,
  'mta-med-journal': mtaScheme,
  'med-journal-old-format': {
    fields: [
      {
        preset: 'Organizations',
        validation: { required },
      },
      {
        preset: 'DateRange',
        validation: { required },
      },
    ],
    fixedValues: {
      timezone: MOSCOW_TIMEZONE,
      timeDisplayMode: 'system',
    },
  },
  'med-journal-edo': edoScheme,
};

export default schemes;
