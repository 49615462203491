<template>
  <v-card class="mb-3">
    <v-toolbar flat>
      <v-toolbar-title class="mr-5">
        <v-btn icon @click="changeReport()">
          <v-icon>fa-arrow-left</v-icon>
        </v-btn>
        <span>Выгрузка отчета</span>
      </v-toolbar-title>
      <span v-if="reports.length === 1"> – {{ reports[0].name }} </span>
      <v-autocomplete
        v-else
        single-line
        hide-details
        :items="reports"
        item-value="key"
        item-text="name"
        :value="currentKey"
        @input="changeReport"
      />
    </v-toolbar>
    <v-divider />
    <v-card-text v-if="!report" class="text-center">
      <div>Такого отчета не существует</div>
      <v-btn class="mt-2" color="primary" depressed :to="{ name: 'reports' }">
        Назад
      </v-btn>
    </v-card-text>
    <template v-else>
      <div class="ma-6">
        <component
          :is="component"
          ref="form"
          :fields="report.fields"
          :value="filters"
          @input="changeFilters"
        />
      </div>
      <v-divider />
      <div class="mx-6 my-3 text-right">
        <ExportButton :formats="report.formats" @click="onSubmit" />
      </div>
    </template>
    <v-divider />
  </v-card>
</template>

<script>
import ExportButton from '@/modules/reports/components/ExportButton';
import { mapGetters, mapActions } from 'vuex';

export default {
  components: { ExportButton },
  props: {
    changeReport: { type: Function, default: () => {} },
  },
  computed: {
    ...mapGetters('AVAILABLE_REPORTS', ['reports', 'reportByKey']),
    ...mapGetters('REPORTS', ['filters']),

    currentKey() {
      return this.$route.params.key;
    },
    report() {
      return this.reportByKey(this.currentKey);
    },
    component() {
      return this.report.formComponent;
    },
  },
  methods: {
    ...mapActions('REPORTS', ['export', 'changeFilters']),

    async onSubmit(format) {
      const form = this.$refs.form;
      if (!form.validate()) return;

      const reportName = this.report.name;
      const filters = this.report.prepareValues(this.filters);
      const type = this.report.reportType
        ? this.report.reportType(filters)
        : 'file';

      try {
        await this.export({
          key: this.currentKey,
          format,
          filters,
          type,
        });
      } catch (err) {
        console.error(err);
        this.$notify({
          group: 'note',
          type: 'error',
          title: 'Ошибка выгрузки отчета',
          text: `Произошла ошибка при выгрузке отчета "${reportName}". Попробуйте позже.`,
        });
      }
    },
  },
};
</script>
