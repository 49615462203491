import { GENDER } from '@/utils/constants';
import { date, name } from '@/utils/convert';
import { maskTextPhone, maskTextSnils } from '@/utils/masks';
import required from 'vuelidate/lib/validators/required';

export const entity = [
  { key: 'id', label: 'ID' },
  {
    key: 'isEnabled',
    label: 'Статус',
    headerProps: {
      width: '48px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
    convert: item => (item.isEnabled ? 'Активен' : 'Неактивен'),
  },
  { key: 'name', label: 'ФИО', hideInTable: true, convert: item => name(item) },
  { key: 'nameOrg', label: 'ФИО / Организация', hideInCard: true },
  {
    key: 'organization',
    label: 'Организация',
    hideInTable: true,
    convert: item => item.organization.name,
  },
  {
    key: 'groups',
    label: 'Группы',
    convert: item => item.groups.map(group => group.name).join(', '),
  },
  {
    key: 'personnelNumber',
    headerProps: {
      width: '110px',
      align: 'center',
    },
    label: 'Табельный номер',
  },
  {
    key: 'gender',
    label: 'Пол',
    headerProps: {
      width: '40px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
    convert: item => GENDER[item.gender],
  },
  {
    key: 'dateOfBirth',
    label: 'Дата рождения',
    headerProps: {
      width: '110px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
    convert: item => date(item.dateOfBirth),
  },
  {
    key: 'phone',
    label: 'Телефон',
    headerProps: {
      width: '160px',
    },
    convert: item => maskTextPhone(item.phone),
  },
  {
    key: 'snils',
    label: 'СНИЛС',
    hideInTable: true,
    convert: item => maskTextSnils(item.snils),
  },

  // import
  { key: 'orgId', forImport: true, validation: { required } },
];

export const entityEmbedded = [
  { key: 'id', label: 'ID' },
  {
    key: 'isEnabled',
    label: 'Статус',
    headerProps: {
      width: '48px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
  },
  { key: 'name_gender_birthDay', label: 'ФИО' },
  {
    key: 'groups',
    label: 'Группы',
    convert: item => item.groups.map(group => group.name).join(', '),
  },
  {
    key: 'personnelNumber',
    headerProps: { width: '110px', align: 'center' },
    label: 'Табельный номер',
  },
  {
    key: 'phone',
    label: 'Телефон',
    headerProps: { width: '160px' },
    convert: item => maskTextPhone(item.phone),
  },
];

const fieldTitle = (str, required = false) =>
  `<span ${required ? 'class="red--text text--lighten-1"' : ''}><b>${str}${
    required ? ' *' : ''
  }</b></span>`;

const monospaceText = str =>
  `<span style="font-family: monospace">${str}</span>`;

export const messages = {
  resourceName: 'работников',
  searchFilterLabel: 'Поиск по работникам',
  searchFilterHint: 'ФИО / Табельный номер / Телефон',
  searchFilterPlaceholder: '',
  import: {
    title: 'Импорт работников',
    actionButton: 'Импортировать',
    successfulAction: 'Ресурс успешно импортирован',
    errorAction: 'Произошла ошибка импортирования ресурса',
    instruction: [
      `${fieldTitle(
        '1. Табельный номер',
        true,
      )} – цифры, латиница или кириллица только в верхнем регистре, также допускается дефис, при условии, что в табельном номере помимо дефиса присутствует хотя бы одна цифра или буква (дефис не должен быть первым символом табельного номера), от 1 до 20 символов (если иное не предусмотрено настройкой организации). Табельный номер должен быть уникальным в рамках одной организации.`,
      `${fieldTitle(
        '2. Фамилия',
        true,
      )} – текстовое поле (только кириллица), максимальное количество символов - 50, двойные и тройные фамилии указываются через 1 пробел или дефис без пробелов. Начальная буква первого слова вводится в верхнем регистре, вторая (после пробела или дефиса) - не имеет значения.`,
      `${fieldTitle(
        '3. Имя',
        true,
      )} – текстовое поле (только кириллица), максимальное количество символов - 50, двойные или тройные имена указываются через 1 пробел или дефис без пробелов. Начальная буква первого слова вводится в верхнем регистре, вторая (после пробела или дефиса) - не имеет значения.`,
      `${fieldTitle(
        '4. Отчество',
      )} – текстовое поле (только кириллица), максимальное количество символов - 50, двойные или тройные отчества указываются через 1 пробел или дефис без пробелов. Начальная буква первого слова вводится в верхнем регистре, вторая (после пробела или дефиса) - не имеет значения.`,
      `${fieldTitle(
        '5. Пол',
        true,
      )} – указывается цифрой: 1 - мужской, 2 - женский.`,
      `${fieldTitle('6. Дата рождения', true)} – в формате ${monospaceText(
        'ГГГГ-ММ-ДД',
      )} или ${monospaceText(
        'ДД.ММ.ГГГГ',
      )}, присутствует проверка на возраст ` +
        '(работнику должно быть минимум 18 полных лет и не более 100).',
      `${fieldTitle('7. Телефон')} – вносится в формате ${monospaceText(
        '+7**********',
      )}.`,
      `${fieldTitle(
        '8. ID Групп',
      )} – идентификаторы групп работников. Вносятся ID только существующих в системе групп работников, через запятую без пробелов. Только цифры. При импорте существующего работника с указанием групп текущий список групп этого работника заменяется, а не дополняется.`,
      `${fieldTitle(
        '9. СНИЛС',
      )} – текстовое поле, указывается только цифрами в формате XXXXXXXXXXX, без пробелов и дефиса, кол-во символов - 11. Обязательность поля регулируется в настройках организации.`,
      `${fieldTitle(
        '10. Статус',
      )} – статус работника. Указывается цифрой: 1 - активный, 0 - неактивный.`,
      '<br />',
      'Можно поставить галочку "Только создание", чтобы добавить новых работников и не обновлять данные существующих. Если галочка не установлена, то по табельным номерам, ' +
        'которые уже есть в системе, данные будут обновлены.',
      `Поля, отмеченные ${fieldTitle(
        '',
        true,
      )} обязательны к заполнению. Остальные поля можно оставить пустыми.`,
    ],
  },
};

export const filters = {
  isEnabled: {
    type: Boolean,
    default: true,
  },
  orgIds: {
    type: Array,
    default: null,
  },
  gender: {
    type: String,
    default: null,
  },
  groupIds: {
    type: Array,
    default: null,
  },
  isTest: {
    type: Boolean,
    default: null,
  },

  // fixed filter
  includeSystemGroups: {
    type: Boolean,
    default: false,
  },
};
