import Vue from 'vue';
import jwtDecode from 'jwt-decode';

// api
import processingApi from '@/api/services/processing';
import authApi from '@/api/services/auth';

import { authWindowManager, debug } from '@/utils/helpers';
import { Sentry } from '@/plugins/sentry';
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  ACTIVE_SIGNATURE_TOKEN_KEY,
} from '@/utils/constants';
import env from '@/plugins/env';
import { WebStorage } from '@/plugins/webStorage';

const DELETE_RIGHTS = ['EMP_D', 'MED_D', 'HST_D', 'PNT_D', 'ORG_D'];

const authLog = debug('AUTH', '#DC5B4A');

function dataFromTokenForSentry({ accessPayload, refreshPayload }) {
  const { access } = accessPayload;

  Sentry.setTag('reference_id', access?.referenceId);
  Sentry.setTag('account_type', access?.type);
  Sentry.setTag('account_role', access?.role?.key);
  Sentry.setUser({
    id: access?.id,
    email: access?.email,
    profileType: access?.profile?.type,
    loginType: access?.loginType,
    type: access?.type,
    'ID (referenceId)': access?.referenceId,
    role: {
      id: access?.role?.key,
      name: access?.role?.name,
    },
  });

  try {
    Sentry.setContext('Tokens expire', {
      ...(accessPayload && {
        access: new Date(accessPayload.exp * 1000).toString(),
      }),
      ...(refreshPayload && {
        refresh: new Date(refreshPayload.exp * 1000).toString(),
      }),
    });
  } catch (e) {
    console.error('Не удалось задать время токена.', e);
  }
}

const state = {
  currentAccount: null,
  // stores only which sound to play in medcab and whether to repeat it or not
  settings: null,

  availableActions: [],
  testCredentials: [],

  refreshing: null,
  softSignIn: false,
  medicDetails: null,
  disabled2fa: false,
};

const getters = {
  softSignIn: state => state.softSignIn,

  currentAccount: state => state.currentAccount || {},
  actions: (_, { currentAccount }) =>
    Object.freeze(currentAccount.actions || []),
  can:
    (_, { actions }) =>
    action =>
      actions
        // NOTE: for some 'weird' reason that sounds like 'we have root user,
        // which has all rights, we can't remove these particular rights from
        // all the other users' we r MANAGING USER RIGHTS ON FRONTEND.
        // The end is coming (read the first commit of this project)
        .filter(action => !DELETE_RIGHTS.includes(action))
        .includes(action),

  permissionsForSigning: (_, { can }) =>
    can('SGNT_R') && (can('SGNT_T') || can('SGNT_TR')) && can('SGNT_C'),
  accountId: (_, { currentAccount }) => currentAccount.id,
  referenceId: (_, { currentAccount }) => currentAccount.referenceId,

  role: (_, { currentAccount }) => currentAccount.role,
  roleTypeName: (_, { role }) => role?.type?.name,
  isRoot: (_, { role }) => role?.key === 'root',
  isMedic: (_, { role }) => role?.type?.key === 'medic',
  needCertInitialize: (_, { currentAccount }) =>
    currentAccount?.profile?.isInitialized !== true,
  orgId: (_, { currentAccount }) => currentAccount.profile?.orgId,
  fullAccessForBindings: (_, getters) =>
    getters.actions !== null ? getters.actions.includes('ORG_AL') : false,

  availableActions: state => state.availableActions,
  testCredentials: state => state.testCredentials,

  medicDetails: state => state.medicDetails || {},
  medicOrganizationId: (_, { medicDetails }) => medicDetails?.orgId,

  settings: state => state.settings || {},

  disabled2fa: state => state.disabled2fa || false,

  // Для медиков предусмотрена двухфакторная аутентификация через криптопро
  isAuthorized: (state, { isMedic, needCertInitialize, disabled2fa }) =>
    (!!state.currentAccount &&
      (!isMedic || needCertInitialize || disabled2fa)) ||
    state.currentAccount?.loginType === 'cryptopro',
};

const mutations = {
  signIn: (state, { accessPayload, refreshPayload }) => {
    state.currentAccount = accessPayload.access;
    state.availableActions = [...new Set(accessPayload.access.actions)].sort();
    state.refreshing = null;
    state.softSignIn = false;

    dataFromTokenForSentry({ accessPayload, refreshPayload });
  },

  refreshing: (state, promise) => {
    state.refreshing = promise;
  },
  refreshed: (state, { accessPayload, refreshPayload }) => {
    state.currentAccount = accessPayload.access;
    state.availableActions = [...new Set(accessPayload.access.actions)].sort();
    state.refreshing = null;
    state.softSignIn = false;

    dataFromTokenForSentry({ accessPayload, refreshPayload });
  },
  needSoftSignIn: state => {
    state.softSignIn = true;
  },

  testCredentials: (state, value) => (state.testCredentials = value),
  debugChangeActions: (state, value) => {
    Vue.set(state.currentAccount, 'actions', value);
  },
  medicDetails: (state, value) => (state.medicDetails = value),
  settings: (state, value) => (state.settings = value),
  disabled2fa: (state, value) => (state.disabled2fa = value),
};

const actions = {
  init({ commit, dispatch }) {
    // For comfortable development
    const credentials = String(env.get('VUE_APP_TEST_CREDENTIALS') || '')
      .split(';')
      .filter(item => item)
      .map(item => item.split(':'));

    commit('testCredentials', credentials);

    // sync changes in local storage with other browser tabs
    window.addEventListener('storage', event => {
      if (event.key === ACCESS_TOKEN_KEY)
        dispatch('syncAuthSession', event.newValue);
    });
  },

  async signIn({ commit, getters }, { credentials, type }) {
    const TYPES = {
      email_pwd: () => authApi.logInGeneral(credentials, 'email_pwd'),
      cryptopro: () => authApi.logInCryptoPro(credentials),
    };
    if (!TYPES[type]) throw new Error('Unknown auth type');

    const userId = getters.accountId;
    const result = await TYPES[type]();

    if (result.nextType === 'esia') {
      const redirectUrl = window.location.href + '?type=esia';
      const { url } = await authApi.esiaAuthUrl(redirectUrl);
      try {
        const code = await authWindowManager(url, 'code');
        const { access, refresh } = await authApi.esiaAuth(
          code,
          credentials.username,
          redirectUrl,
        );
        result.access = access;
        result.refresh = refresh;
      } catch (e) {
        if (e.response?.data) {
          const esiaError = new Error(e.response?.data?.message);
          esiaError.type = 'esia_error';
          throw esiaError;
        }
        throw new Error('esia_error');
      }
    }

    const accessPayload = jwtDecode(result.access);
    const refreshPayload = jwtDecode(result.refresh);

    if (
      type === 'email_pwd' &&
      accessPayload.access.type === 'medic' &&
      !accessPayload.access.profile.certificateType &&
      !getters.disabled2fa
    ) {
      throw Error('no_certificate');
    }
    if (type === 'cryptopro' && userId !== accessPayload.access.id) {
      throw Error('account_mismatch');
    }
    WebStorage.setItem(ACCESS_TOKEN_KEY, result.access);
    WebStorage.setItem(REFRESH_TOKEN_KEY, result.refresh);

    commit('signIn', { accessPayload, refreshPayload });

    try {
      authLog(
        'User successfully signed in:',
        Object.freeze(JSON.parse(JSON.stringify(accessPayload.access))),
      );
    } catch (e) {}
  },

  async restoreSession({ commit }) {
    const access = WebStorage.getItem(ACCESS_TOKEN_KEY);
    if (!access) return;

    const refresh = WebStorage.getItem(REFRESH_TOKEN_KEY);
    const accessPayload = jwtDecode(access);
    const refreshPayload = jwtDecode(refresh);

    commit('signIn', { accessPayload, refreshPayload });
    try {
      authLog(
        'User successfully restored:',
        Object.freeze(JSON.parse(JSON.stringify(accessPayload.access))),
      );
    } catch (e) {}
  },

  async signOut() {
    WebStorage.removeItem(ACCESS_TOKEN_KEY);
    WebStorage.removeItem(REFRESH_TOKEN_KEY);
    WebStorage.removeItem(ACTIVE_SIGNATURE_TOKEN_KEY);
    document.location.assign(document.location.origin);
  },

  async getAccessToken({ dispatch }) {
    const accessToken = WebStorage.getItem(ACCESS_TOKEN_KEY);
    if (!accessToken) throw new Error('User is not authorized');

    const accessPayload = jwtDecode(accessToken);
    const currentSeconds = new Date().valueOf() / 1000;

    // Access token timed out, token needs to refresh
    if (currentSeconds > accessPayload.exp - 20) {
      const a = await dispatch('refreshToken');
      return a.access;
    }

    return accessToken;
  },

  refreshToken({ commit, getters, state, dispatch }) {
    // If we are already refreshing token, other requests have to wait for single promise
    if (state.refreshing) return state.refreshing;

    const promise = new Promise((resolve, reject) => {
      const refreshToken = WebStorage.getItem(REFRESH_TOKEN_KEY);
      const refreshPayload = jwtDecode(refreshToken);
      const currentSeconds = new Date().valueOf() / 1000;

      // Refresh token timed out, user must perform soft auth
      if (currentSeconds > refreshPayload.exp - 20)
        reject(new Error('Refresh token timed out'));
      else {
        const { id: userId, loginType, matchBy } = getters.currentAccount;
        authApi
          .refreshTokens(refreshToken, {
            userId,
            type: loginType,
            matchBy,
          })
          .then(resolve, reject);
      }
    })
      .then(payload => dispatch('saveRefreshToken', payload))
      .catch(error => dispatch('waitingSoftAuth', error));

    commit('refreshing', promise);
    return promise;
  },

  saveRefreshToken({ commit }, { access, refresh }) {
    const accessPayload = jwtDecode(access);
    const refreshPayload = jwtDecode(refresh);

    WebStorage.setItem(ACCESS_TOKEN_KEY, access);
    WebStorage.setItem(REFRESH_TOKEN_KEY, refresh);

    commit('refreshed', { accessPayload, refreshPayload });

    try {
      authLog(
        'User tokens successfully refreshed:',
        Object.freeze(JSON.parse(JSON.stringify(accessPayload.access))),
      );
    } catch (e) {}

    return { access, refresh };
  },

  waitingSoftAuth({ commit, getters }, error) {
    authLog('User must perform soft auth:', error.message);
    commit('needSoftSignIn');

    return new Promise(resolve => {
      // We create promise that will only be resolved when the user performs soft auth
      const intervalId = setInterval(() => {
        if (getters.softSignIn) return;

        authLog('User token successfully refreshed through soft auth');
        clearInterval(intervalId);

        resolve({
          access: WebStorage.getItem(ACCESS_TOKEN_KEY),
          refresh: WebStorage.getItem(REFRESH_TOKEN_KEY),
        });
      }, 100);
    });
  },

  syncAuthSession({ getters, dispatch, commit }, newAccessToken) {
    // user logged out in another tab
    if (!newAccessToken && getters.isAuthorized) return dispatch('signOut');

    // user updated token in another tab
    if (newAccessToken) {
      const accessPayload = jwtDecode(newAccessToken);
      return commit('signIn', { accessPayload });
    }
  },

  async fetchMedicDetails({ getters, commit }) {
    if (getters.currentAccount.type !== 'medic')
      throw Error('Тип вашего аккаунта не медработник');
    const data = await processingApi.myMedic();

    commit('medicDetails', data);
  },

  fetchGroups: (_, params) => authApi.fetchGroups(params),
  fetchGroupsPreviewsBy: (_, params) => authApi.fetchGroupsPreviewsBy(params),

  async fetchSettings({ commit, getters }, id) {
    const settings = await authApi.getSettings(id || getters.currentAccount.id);
    commit('settings', settings);
    return settings;
  },

  async updateSettings({ commit, getters }, params) {
    const settings = await authApi.putSettings(
      getters.currentAccount.id,
      params,
    );
    commit('settings', settings);
    return settings;
  },
  async getDisabled2fa({ commit }) {
    const { disable2fa } = await authApi.getDisabled2fa();
    commit('disabled2fa', disable2fa);
  },
  fetchAppAccountsPreviews: (_, params) =>
    authApi.getAppAccountsPreviews(params),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
