import { FieldPresets } from '../models';
import { prepareDate } from '../utilities';
import { getUserTimeZone } from '@/utils/convert';
import store from '@/store';

const fieldPresets: FieldPresets = {
  // Организация
  Organization: {
    component: () =>
      import('@/components/controls/structures/OrganizationSelect.vue'),
    name: 'orgId',
  },
  // field-wrapper on top of organization select above
  // preloads available org ids
  // this component and the one above must NOT be used together
  RiskAvailableOrgs: {
    component: () => import('../components/fields/RiskAvailOrgSelect.vue'),
    name: 'orgId',
  },
  Organizations: {
    component: () =>
      import('@/components/controls/structures/OrganizationSelect.vue'),
    name: 'orgIds',
    props: { multiple: true },
    defaultValue: [],
  },

  // Группа организаций
  OrganizationGroup: {
    component: () =>
      import('@/components/controls/structures/OrganizationGroupSelect.vue'),
    name: 'orgGroupId',
  },

  // Группа организаций
  OrganizationGroupMultiple: {
    component: () =>
      import('@/components/controls/structures/OrganizationGroupSelect.vue'),
    props: { multiple: true },
    name: 'orgGroupIds',
  },

  // ПАК
  Host: {
    component: () => import('@/components/controls/structures/HostSelect.vue'),
    name: 'hostId',
  },
  Hosts: {
    component: () => import('@/components/controls/structures/HostSelect.vue'),
    name: 'hostIds',
    props: { multiple: true },
    prepareValue: (name, value) =>
      Array.isArray(value) && !value.length ? undefined : [name, value],
  },

  // Группа ПАКов
  HostGroup: {
    component: () =>
      import('@/components/controls/structures/HostGroupSelect.vue'),
    name: 'hostGroupId',
    props: {
      includeGuests: true,
    },
  },
  HostGroups: {
    component: () =>
      import('@/components/controls/structures/HostGroupSelect.vue'),
    name: 'hostGroupIds',
    props: { multiple: true, includeGuests: true },
    prepareValue: (name, value) =>
      Array.isArray(value) && !value.length ? undefined : [name, value],
  },

  // Точка выпуска
  Point: {
    component: () => import('@/components/controls/structures/PointSelect.vue'),
    name: 'pointId',
    props: {
      includeGuests: true,
    },
  },
  Points: {
    component: () => import('@/components/controls/structures/PointSelect.vue'),
    name: 'pointIds',
    props: { multiple: true, includeGuests: true },
    prepareValue: (name, value) =>
      Array.isArray(value) && !value.length ? undefined : [name, value],
  },

  // Группа работников
  EmployeeGroup: {
    component: () =>
      import('@/components/controls/structures/EmployeeGroupSelect.vue'),
    name: 'employeeGroupId',
  },
  EmployeeGroups: {
    component: () =>
      import('@/components/controls/structures/EmployeeGroupSelect.vue'),
    name: 'employeeGroupIds',
    props: { multiple: true },
    prepareValue: (name, value) =>
      Array.isArray(value) && !value.length ? undefined : [name, value],
  },

  // Группа медиков
  MedicGroup: {
    component: () =>
      import('@/components/controls/structures/MedicGroupSelect.vue'),
    name: 'medicGroupId',
  },
  MedicGroups: {
    component: () =>
      import('@/components/controls/structures/MedicGroupSelect.vue'),
    name: 'medicGroupIds',
    props: { multiple: true },
    prepareValue: (name, value) =>
      Array.isArray(value) && !value.length ? undefined : [name, value],
  },

  // Часы отдыха
  HoursRestMinutes: {
    component: () => import('../components/fields/HoursRestField.vue'),
    name: 'hoursRestMinutes',
    defaultValue: 10,
    prepareValue: (_, value) => ['minRestMinutes', 60 * (value as number)],
  },

  // Тип осмотра
  InspectionTypes: {
    component: () => import('../components/fields/InspectionTypeSelect.vue'),
    name: 'inspectionTypes',
    props: {
      multiple: true,
    },
  },

  // Результат осмотра
  ResolutionSuccess: {
    component: () => import('../components/fields/ResolutionSuccessSelect.vue'),
    name: 'resolutionSuccess',
  },

  // Report type
  ReportType: {
    component: () => import('../components/fields/TypeSelect.vue'),
    name: 'type',
    defaultValue: 'default',
  },

  // Импортированные осмотры
  ImportedInspections: {
    component: () => import('../components/fields/ImportedInspections.vue'),
    name: 'isImported',
    prepareValue: (name, value) => (value === null ? undefined : [name, value]),
  },

  // Дата
  Date: {
    component: () => import('../components/fields/Date.vue'),
    name: ['dateStart', 'dateEnd'],
    slot: 'date',
    prepareValue: (name, value, filters) => {
      const result = filters?.noTimezone
        ? value
        : prepareDate(value as string, filters.timezone as number);
      return [name, result];
    },
  },
  DateRange: {
    component: () => import('../components/fields/DateRange.vue'),
    name: ['dateStart', 'dateEnd'],
    slot: 'dateRange',
    prepareValue: (name, value, filters) => {
      const result = filters?.noTimezone
        ? value
        : prepareDate(value as string, filters.timezone as number);
      return [name, result];
    },
  },
  Timezone: {
    is: context => !context.noTimezone,
    component: () => import('../components/fields/TimezoneSelect.vue'),
    name: 'timezone',
    defaultValue: getUserTimeZone(),
    prepareValue: () => undefined,
  },
  TimeDisplayMode: {
    component: () => import('../components/fields/TimeDisplayModeSelect.vue'),
    name: 'timeDisplayMode',
    defaultValue: 'host',
  },

  // Чекбоксы
  Checkbox: {
    component: () => import('../components/fields/Checkbox.vue'),
  },
  IncludeTestCheckbox: {
    component: () => import('../components/fields/Checkbox.vue'),
    name: 'includeTest',
    defaultValue: false,
    props: {
      label: 'Учитывать тестовые осмотры',
    },
  },
  IncludeCancelledCheckbox: {
    component: () => import('../components/fields/Checkbox.vue'),
    name: 'includeCancelled',
    defaultValue: true,
    props: {
      label: 'Учитывать незавершенные осмотры',
    },
  },
  IncludeInspectedCheckbox: {
    component: () => import('../components/fields/Checkbox.vue'),
    is: () => store.getters['AUTH/can']('I_RIA'),
    name: 'isInspected',
    defaultValue: false,
    props: {
      label: 'С контролем качества',
    },
  },
  IncludeTechnicalCheckbox: {
    component: () => import('../components/fields/Checkbox.vue'),
    name: 'includeTechnicalErrors',
    defaultValue: false,
    props: {
      label: 'Учитывать осмотры с техническими ошибками',
    },
  },
  IncludeStepsCheckbox: {
    component: () => import('../components/fields/Checkbox.vue'),
    name: 'includeSteps',
    defaultValue: false,
    props: {
      label: 'Отобразить этапы осмотра',
    },
  },
  SetAllOrgs: {
    component: () => import('../components/fields/Checkbox.vue'),
    name: 'setAllOrgs',
    is: () =>
      store.getters['AUTH/isRoot'] || store.getters['AUTH/can']('R_AORG'),
    defaultValue: false,
    props: {
      label: 'По всем организациям',
    },
  },
  IncludeActivePoints: {
    component: () => import('../components/fields/Checkbox.vue'),
    name: 'includeActivePoints',
    defaultValue: false,
    props: {
      label: 'По всем активным точкам',
    },
  },
  IncludeAllPoints: {
    component: () => import('../components/fields/Checkbox.vue'),
    name: 'includeAllPoints',
    defaultValue: false,
    props: {
      label: 'По всем точкам',
    },
  },
  CompositeFile: {
    component: () => import('../components/fields/Checkbox.vue'),
    name: 'compositeFile',
    defaultValue: false,
    props: { label: 'Одним файлом' },
  },

  // Выбор полей осмотра
  Choose: {
    component: () => import('../components/fields/Choose.vue'),
  },
};

export default fieldPresets;
