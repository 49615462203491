import { name } from '@/utils/convert';

export const entity = [
  { label: 'ID', key: 'id' },
  {
    label: 'Статус',
    key: 'isEnabled',
    headerProps: {
      width: '60px',
      align: 'center',
      class: 'pa-0',
      cellClass: 'pa-0',
    },
    convert: item => (item.isEnabled ? 'Активный' : 'Неактивный'),
  },
  {
    label: 'Название приложения',
    key: 'name',
    headerProps: { width: '200px' },
    convert: item => name(item.profile),
  },
  {
    key: 'email',
    label: 'Почта для связи',
    convert: item => item.profile.email,
  },
];

export const messages = {
  resourceName: 'приложений',
  searchFilterLabel: 'Поиск по приложениям',
  searchFilterPlaceholder: '',
};

export const filters = {
  roleKeys: { type: Array, default: null },
  isEnabled: { type: Boolean, default: true },
};
